import { API_ENDPOINTS } from '../../../../../config/apiConfig'
import { auth } from '../../../../../firebase/firebase'
import store from '../../../../../store'
import { refreshToken } from '../../../../../slices/authSlice'

const searchHandler = async (fileId, searchText, userIdToken, userId, navigate, location, isRetry = false) => {
    const apiUrl = `${API_ENDPOINTS.manualAddition}?file_id=${fileId}`

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userIdToken}`,
                accept: 'application/json'
            },
            body: JSON.stringify(searchText)
        })

        // Handle 401 unauthorized error - token expired
        if (response.status === 401 && !isRetry) {
            console.log('Token expired, attempting to refresh...')
            try {
                // Dispatch the refreshToken action and wait for it to complete
                const refreshResult = await store.dispatch(refreshToken()).unwrap()
                const newToken = refreshResult.userIdToken

                // Retry the request with the new token
                return searchHandler(fileId, searchText, newToken, userId, navigate, location, true)
            } catch (refreshError) {
                console.error('Token refresh failed:', refreshError)
                const confirm = window.confirm('Your session has expired. Please sign out and sign in again.')
                if (confirm) {
                    await auth.signOut()
                    navigate('/signin', { state: { from: location.pathname } })
                }
                throw new Error('Session expired')
            }
        }

        const data = await response.json()
        // console.log('data', data.entities)

        if (response.status === 200) {
            return data.entities
        } else if (response.status === 401) {
            const confirm = window.confirm('Your session has expired. Please sign out and sign in again.')
            if (confirm) {
                await auth.signOut()
                navigate('/signin', { state: { from: location.pathname } })
            }
        } else {
            console.error('Error:', data.detail || 'An unexpected error occurred')
            throw new Error(data.detail || 'An unexpected error occurred')
        }
    } catch (error) {
        console.error('An unexpected error occurred:', error)
        throw error
    }
}

export default searchHandler
