import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Checkbox } from '@mui/material'
import { styled } from '@mui/material/styles'
import Button from '../../../../components/Button/Button'

// Replace makeStyles with styled API
const StyledDialogContent = styled('div')(({ theme }) => ({
    '& .description': {
        marginBottom: theme.spacing(2)
    },
    '& .checkboxLabel': {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    }
}))

function AnonymizationOptionsDialog ({ open, onClose, onAnonymize }) {
    const [anonymizeImages, setAnonymizeImages] = useState(true)

    const handleAnonymizationButtonClick = () => {
        onAnonymize(anonymizeImages)
        onClose()
    }

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    onClose()
                }
            }}
        >
            <DialogTitle>Auswahl der Anonymisierung</DialogTitle>
            <DialogContent>
                <StyledDialogContent>
                    <Typography className="description">
                        Hinweis: Sie verwenden die Testversion von AnonLab. Die ersten 10 Seiten werden anonymisiert
                    </Typography>
                    <div className="checkboxLabel">
                        <Checkbox
                            checked={anonymizeImages}
                            onChange={(e) => setAnonymizeImages(e.target.checked)}
                            color="primary"
                        />
                        <Typography variant="body1">Bilddaten (Unterschriften, Logos, etc.) anonymisieren</Typography>
                    </div>
                </StyledDialogContent>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    isCTA
                    label="Abbrechen"
                />
                <Button
                    onClick={handleAnonymizationButtonClick}
                    isCTA
                    label="Anonymisieren"
                />
            </DialogActions>
        </Dialog>
    )
}

AnonymizationOptionsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAnonymize: PropTypes.func.isRequired
}

export default AnonymizationOptionsDialog
