import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, LinearProgress, styled, Alert } from '@mui/material'
import { ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from '../../../../../../firebase/firebase'
import { encryptData } from './Utils'
import { getFirestore, collection, doc, setDoc, Timestamp, getDocs, query, where } from 'firebase/firestore'
import Button from '../../../../../../components/Button/Button'
import WarningIcon from '@mui/icons-material/Warning'

// Styled components for the dialog and progress bar
const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0
}))

function useUploadFileLogic (userId, parentId, refreshFolderTree, setContextMenu) {
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [file, setFile] = useState(null)
  const [fileSizeError, setFileSizeError] = useState(false)
  const db = getFirestore() // Initialize Firestore

  // Maximum file size: 30MB
  const MAX_FILE_SIZE = 30 * 1024 * 1024

  const handleUploadFileClick = () => {
    setContextMenu(null)
    setUploadDialogOpen(true)
    setFileSizeError(false)
  }

  const checkFileExists = async (fileName, userId, parentId) => {
    const filesRef = collection(db, 'files')
    const querySnapshot = await getDocs(query(filesRef, where('name', '==', fileName), where('user_id', '==', userId), where('parent_id', '==', parentId)))
    return !querySnapshot.empty
  }

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0]
    if (selectedFile) {
      setFile(selectedFile)

      // Check file size
      if (selectedFile.size > MAX_FILE_SIZE) {
        setFileSizeError(true)
      } else {
        setFileSizeError(false)
      }
    }
  }

  const handleUploadFile = async () => {
    if (!file) {
      return
    }

    // Double-check file size before upload
    if (file.size > MAX_FILE_SIZE) {
      setFileSizeError(true)
      return
    }

    setUploading(true)
    if (file) {
      const fileExists = await checkFileExists(file.name, userId, parentId)
      if (fileExists) {
        setUploadDialogOpen(false)
        setUploading(false)
        alert('Eine Datei mit demselben Namen existiert bereits in diesem Ordner.')
        return
      }
      // Encrypt the file data
      const fileReader = new FileReader()
      fileReader.onload = async (e) => {
        const arrayBuffer = e.target.result
        const { encryptedData, rawKey, iv } = await encryptData(arrayBuffer)

        // Create a reference to the files collection
        const filesRef = collection(db, 'files')
        const fileDocumentRef = doc(filesRef)

        // Create a reference to the location in Google Storage
        const fileRef = ref(storage, `userFiles/${userId}/${parentId}/${file.name}`)

        // Upload the encrypted data to Google Storage
        const uploadTask = uploadBytesResumable(fileRef, new Uint8Array(encryptedData))
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            setUploadProgress(progress)
          },
          (error) => {
            // Handle unsuccessful uploads
            console.error(error)
            setUploadDialogOpen(false)
            setUploading(false) // End the uploading process
            setUploadProgress(0) // Reset progress
            refreshFolderTree() // Refresh the tree
          },
          async () => {
            // Create a file document
            const fileDocument = {
              id: fileDocumentRef.id,
              name: file.name,
              user_id: userId,
              parent_id: parentId,
              cryptoKey: Array.from(rawKey), // Convert to regular array
              iv: Array.from(iv), // Convert to regular array
              created_at: Timestamp.now()
            }

            // Save the file document
            await setDoc(fileDocumentRef, fileDocument)

            setUploadDialogOpen(false)
            setUploading(false) // End the uploading process
            setUploadProgress(0) // Reset progress
            setFile(null) // Reset the file
            refreshFolderTree() // Refresh the tree
          }
        )
      }
      fileReader.readAsArrayBuffer(file)
    }
    // setUploadDialogOpen(false)
  }

  const UploadFileDialog = (
      <Dialog
          open={uploadDialogOpen}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
              setUploadDialogOpen(false)
            }
          }}
      >
        {uploading && <StyledLinearProgress variant="determinate" value={uploadProgress} />}
        <DialogTitle>Datei hochladen</DialogTitle>
        <DialogContent>
          <input type="file" onChange={handleFileChange} accept=".pdf" />
          {file && file.type !== 'application/pdf' && (
              <p style={{ color: 'red' }}>Unterstützt derzeit nur PDF Dateien.</p>
          )}
          {fileSizeError && (
            <Alert
              severity="error"
              icon={<WarningIcon />}
              sx={{ marginTop: 2 }}
            >
              Diese Datei ist größer als 30MB und kann nicht hochgeladen werden.
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button
              onClick={() => {
                  setUploadDialogOpen(false)
                  setFile(null)
              }}
              disabled={uploading}
              isCTA={!uploading}
              label="Abbrechen"/>
          <Button
              onClick={handleUploadFile}
              disabled={!file || file.type !== 'application/pdf' || uploading || fileSizeError}
              isCTA={file && file.type === 'application/pdf' && !uploading && !fileSizeError}
              label="Hochladen"/>
        </DialogActions>
      </Dialog>
  )

  return { handleUploadFileClick, UploadFileDialog }
}

export default useUploadFileLogic
