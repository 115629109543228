import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import { API_ENDPOINTS } from '../../../../config/apiConfig'
import PropTypes from 'prop-types'
import store from '../../../../store'
import { refreshToken } from '../../../../slices/authSlice'

const handleDownloadAnonymizedPDF = async (fileId, userId, userIdToken, ners, isRetry = false) => {
    // Filter out entities where anonymize/annonymize is set to false
    const anonymizedNers = ners.filter(ner => ner.annonymize !== false && ner.anonymize !== false)

    // Convert ners to the format expected by the backend
    const formattedEntities = anonymizedNers.map(ner => ({
        bbox: {
            x0: ner.bbox.x0 || ner.bbox[0],
            x1: ner.bbox.x1 || ner.bbox[2],
            y0: ner.bbox.y0 || ner.bbox[1],
            y1: ner.bbox.y1 || ner.bbox[3]
        },
        label: ner.type || ner.category || 'PII',
        page: ner.page,
        text: 's'
    }))
    // console.log('formattedEntities', formattedEntities)

    // Construct the URL with query parameters
    const apiUrl = `${API_ENDPOINTS.redactPDF}?file_id=${encodeURIComponent(fileId)}`

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userIdToken}`,
                accept: 'application/json'
            },
            body: JSON.stringify({ entities: formattedEntities })
        })

        if (response.status === 401 && !isRetry) {
            console.log('Token expired, attempting to refresh...')
            try {
                // Dispatch the refreshToken action and wait for it to complete
                const refreshResult = await store.dispatch(refreshToken()).unwrap()
                const newToken = refreshResult.userIdToken

                // Retry the request with the new token
                return handleDownloadAnonymizedPDF(fileId, userId, newToken, ners, true)
            } catch (refreshError) {
                console.error('Token refresh failed:', refreshError)
                throw new Error('UnauthorizedError')
            }
        }

        if (response.status === 401) {
            throw new Error('UnauthorizedError')
        }

        if (!response.ok) {
            const errorData = await response.json()
            throw new Error(errorData.detail || `HTTP error! status: ${response.status}`)
        }

        // Handle file download
        const blob = await response.blob()
        const downloadUrl = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', 'redacted_file.pdf')
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
        window.URL.revokeObjectURL(downloadUrl)

        return { success: true }
    } catch (error) {
        console.error('An error occurred:', error.message || error)
        throw error
    }
}

const DownloadRedactedButton = ({
                                    fileId,
                                    userId,
                                    userIdToken,
                                    ners,
                                    hasAnonymizationsData,
                                    setIsLoading,
                                    setAnonymizationErrorMessage
                                }) => {
    const navigate = useNavigate()

    const handleDownloadClick = async () => {
        setIsLoading(true)
        try {
            await handleDownloadAnonymizedPDF(fileId, userId, userIdToken, ners)
            console.log('Download successful')
        } catch (error) {
            console.error('Download failed:', error.message || error)
            setAnonymizationErrorMessage(error.message || 'Download failed')
            if (error.message === 'UnauthorizedError') {
                if (window.confirm('Your session has expired. Please sign out and sign in again.')) {
                    navigate('/signin')
                }
            }
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Button
            variant="outlined"
            color="primary"
            className="preview-btn action"
            style={{ marginTop: '0.5rem' }}
            onClick={handleDownloadClick}
            disabled={!hasAnonymizationsData}
        >
            Download anonymisierte PDF
        </Button>
    )
}

DownloadRedactedButton.propTypes = {
    fileId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    userIdToken: PropTypes.string.isRequired,
    ners: PropTypes.array.isRequired,
    hasAnonymizationsData: PropTypes.bool.isRequired,
    setIsLoading: PropTypes.func.isRequired,
    setAnonymizationErrorMessage: PropTypes.func.isRequired
}

export default DownloadRedactedButton
