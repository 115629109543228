export const handleMouseOver = (annotation, setPosition, setShowPopup, setCurrentAnnotation, pdfViewerRef) => (event) => {
  // Get the bounding rectangle of the PDF viewer container
  const containerRect = pdfViewerRef.current.getBoundingClientRect()

  // Add some offset to prevent the popup from appearing directly under the cursor
  const offsetX = 10
  const offsetY = -30 // Negative moves it up from the cursor

  setPosition({
    x: event.clientX - containerRect.left + offsetX,
    y: event.clientY - containerRect.top + offsetY
  })
  setShowPopup(true)
  setCurrentAnnotation(annotation)
}
