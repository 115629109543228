import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { ref, deleteObject, getMetadata } from 'firebase/storage'
import { storage, db } from '../../../../../../firebase/firebase'
import { doc, getDoc, deleteDoc } from 'firebase/firestore'
import Button from '../../../../../../components/Button/Button'

function useDeleteFileLogic (userId, id, refreshFolderTree, setContextMenu, onFileDelete) {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [fileToDelete, setFileToDelete] = useState(null)
  const [fileNameToDelete, setFileNameToDelete] = useState('')
  const [isDeleting, setIsDeleting] = useState(false) // State to track deletion process

  const handleDeleteFileClick = async () => {
    setContextMenu(null)
    setDeleteDialogOpen(true)
    setFileToDelete(id)

    // Fetch the file name and update the state
    const fileDocumentRef = doc(db, 'files', id)
    const fileDocumentSnapshot = await getDoc(fileDocumentRef)
    const fileDocument = fileDocumentSnapshot.data()
    if (fileDocument && fileDocument.name) {
      setFileNameToDelete(fileDocument.name)
    }
  }

  const confirmDeleteFile = async () => {
    setIsDeleting(true)
    onFileDelete()
    if (!fileToDelete) return

    try {
      // Fetch the file document to get the parent_id and name
      const fileDocumentRef = doc(db, 'files', fileToDelete)
      const fileDocumentSnapshot = await getDoc(fileDocumentRef)
      const fileDocument = fileDocumentSnapshot.data()

      if (fileDocument && fileDocument.parent_id && fileDocument.name) {
        // Delete the file from Firebase Storage
        const fileRef = ref(storage, `userFiles/${userId}/${fileDocument.parent_id}/${fileDocument.name}`)
        // Delete the file from Firebase Storage if it exists
        try {
          // Try to get the file's metadata to check if it exists
          await getMetadata(fileRef)
          // If no error, delete the file
          await deleteObject(fileRef)
        } catch (error) {
          if (error.code === 'storage/object-not-found') {
            // File doesn't exist, handle it if needed
            console.log('File not found, no need to delete:', fileDocument.name)
          } else {
            // Some other error occurred, log the error but don't stop execution
            console.error('Error while deleting file:', fileDocument.name, error)
          }
        }
        if (fileDocument.paragraphs_blob_path) {
          const paragraphsBlobRef = ref(storage, fileDocument.paragraphs_blob_path)
          await deleteObject(paragraphsBlobRef)
        }
        if (fileDocument.presidio_entities_blob_path) {
          const entitiesBlobRef = ref(storage, fileDocument.entities_blob_path)
          await deleteObject(entitiesBlobRef)
        }
        if (fileDocument.anonymization_entities_blob_path) {
          const entitiesBlobRef = ref(storage, fileDocument.anonymization_entities_blob_path)
          await deleteObject(entitiesBlobRef)
        }
        // Delete the file document from Firestore
        if (fileDocument) {
          await deleteDoc(doc(db, 'files', fileToDelete))
          refreshFolderTree()
        } else {
          console.error('File document not found or missing parent_id/name')
        }
        refreshFolderTree()
      } else {
        console.error('File document not found or missing parent_id/name')
      }
    } catch (error) {
      console.error('Error deleting file:', error)
    } finally {
      setIsDeleting(false)
      setDeleteDialogOpen(false)
      setFileToDelete(null)
    }
  }

  const DeleteFileDialog = (
        <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
            <DialogTitle>Datei löschen</DialogTitle>
            <DialogContent>
              <p>Sind Sie sicher, dass Sie die Datei &quot;{fileNameToDelete}&quot; löschen wollen?</p> {/* Updated line */}
            </DialogContent>
            <DialogActions>
              <Button
                  onClick={() => setDeleteDialogOpen(false)}
                  label="Abbrechen"
                  disabled={isDeleting}
                  isCTA={!isDeleting}
              />
              <Button
                  onClick={confirmDeleteFile}
                  label="Löschen"
                  disabled={isDeleting}
                  isCTA={!isDeleting}
              />
            </DialogActions>
        </Dialog>
  )

  return { handleDeleteFileClick, DeleteFileDialog }
}

export default useDeleteFileLogic
