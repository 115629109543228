import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from '@mui/material'
import styles from '../Button/Button.module.scss'

export default function SubmitButton ({ formData, disabled, onSuccess }) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitStatus, setSubmitStatus] = useState(null)

  const handleSubmit = () => {
    const callbackName = 'handleResponse'
    window[callbackName] = function (response) {
      if (response.status === 'success') {
        setSubmitStatus('success')
        onSuccess()
      } else {
        setSubmitStatus('error')
        // Log the entire response object to the console
        console.error('Submission error:', response)
        // If the response object contains a specific error message, you can log that instead, e.g.:
        // console.error('Submission error:', response.errorMessage);
      }
      setIsSubmitting(false)
      delete window[callbackName] // Clean up the global function
    }
    const url = 'https://script.google.com/macros/s/AKfycbx9e53oAzyNFukOQgd4CADNFSfaiyzJs9MMWPS_my3wspEtsN-K0867GNI8kYPf5XbiRw/exec'

    const payload = encodeURIComponent(JSON.stringify(formData))
    const script = document.createElement('script')
    script.src = `${url}?callback=${callbackName}&data=${payload}`
    document.body.appendChild(script)
    document.body.removeChild(script) // Clean up the script element
    setIsSubmitting(true)
  }

  return (
    <>
      {submitStatus === null ? <button
        className={`${styles.button} ${styles.action} ${disabled ? styles.disabled : undefined}`}
        aria-label={'Details anfordern'}
        disabled={isSubmitting ?? disabled}
        onClick={handleSubmit}
      >
        {isSubmitting ? <CircularProgress size={10} /> : 'Details anfordern'}
      </button> : null}
      {submitStatus === 'success' ? <button className={`${styles.button} ${styles.success}`}> Erfolgreich gesendet</button> : null}
      {submitStatus === 'error' ? <button className={`${styles.button} ${styles.error}`}> Ein Fehler ist aufgetreten</button> : null}
    </>
  )
}

// Define PropTypes
SubmitButton.propTypes = {
  formData: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func.isRequired
}

SubmitButton.defaultProps = {
  disabled: false
}
