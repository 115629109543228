import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

const folderTreeDataStateSlice = createSlice({
  name: 'selectedFileSlice',
  initialState: {},
  reducers: {
    setFileData: (state, action) => {
      const fileData = action.payload
      const fileId = fileData.id

      if (!state[fileId]) {
        state[fileId] = {}
      }
      state[fileId] = {
        ...fileData
      }
    },
    updateFileData: (state, action) => {
      const { fileId, newData } = action.payload
      if (!state[fileId]) {
        state[fileId] = newData // If the file doesn't exist, just use the new data
      } else {
        // Preserve specific fields from the old data
        const preservedData = {
          url: state[fileId].url
        }
        state[fileId] = {
          ...newData,
          ...preservedData
        }
      }
    },
    removeFileData: (state, action) => {
      const fileId = action.payload
      delete state[fileId]
    },
    updateAnonymizationMapping: (state, action) => {
      const { fileId, newAnonymization } = action.payload
      if (state[fileId]) {
        // If newAnonymization contains entities, add UUID to each entity
        if (newAnonymization.entities && Array.isArray(newAnonymization.entities)) {
          // Map through entities to add a UUID to each one
          const entitiesWithIds = newAnonymization.entities.map(entity => ({
            ...entity,
            id: entity.id || uuidv4(),
            annonymize: true // Default to true for new entities
          }))

          // Replace the entire entities array
          state[fileId].entities = entitiesWithIds
        }
      }
    },
    toggleAnonymization: (state, action) => {
      const { fileId, entityText } = action.payload
      console.log('toggleAnonymization', fileId, entityText)
      if (state[fileId] && state[fileId].entities) {
        state[fileId].entities = state[fileId].entities.map(entity => {
          if (entity.text === entityText) {
            return { ...entity, annonymize: !entity.annonymize }
          }
          return entity
        })
      }
    }
  }
})

export default folderTreeDataStateSlice.reducer
export const {
 setFileData,
  updateFileData,
  updateAnonymizationMapping,
  toggleAnonymization
} = folderTreeDataStateSlice.actions
