import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, CircularProgress } from '@mui/material'
import { deleteDoc, doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore'
import { deleteObject, ref } from 'firebase/storage'
import { db, storage } from '../../../../../../firebase/firebase'
import Button from '../../../../../../components/Button/Button'

function useDeleteFolderLogic (userId, folderId, refreshFolderTree, setContextMenu) {
  const [deleteFolderDialogOpen, setDeleteFolderDialogOpen] = useState(false)
  const [folderToDelete, setFolderToDelete] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)
  const [deletedFilesCount, setDeletedFilesCount] = useState(0)
  const [folderName, setFolderName] = useState('') // State variable to store folder name

  const handleDeleteFolderClick = async () => {
    console.log(`Delete folder clicked for folderId: ${folderId}, userId: ${userId}`)
    setContextMenu(null)
    setDeleteFolderDialogOpen(true)
    setFolderToDelete(folderId)

    // Fetch the folder document to get the name
    const folderDocumentRef = doc(db, 'folders', folderId)
    console.log(`Fetching folder document from Firestore: folders/${folderId}`)
    const folderDocumentSnapshot = await getDoc(folderDocumentRef)
    if (folderDocumentSnapshot.exists()) {
      const folderData = folderDocumentSnapshot.data()
      console.log(`Folder document found: ${JSON.stringify(folderData)}`)
      setFolderName(folderData.name)
    } else {
      console.error(`Folder not found for ID: ${folderId}`)
      setFolderName('')
    }
  }

  const deleteFilesInFolder = async (folderId) => {
    // Query to get files in the folder
    console.log(`Querying files in folder: ${folderId}`)
    const filesQuery = query(collection(db, 'files'), where('parent_id', '==', folderId))
    const querySnapshot = await getDocs(filesQuery)
    console.log(`Found ${querySnapshot.docs.length} files in folder ${folderId}`)

    let count = 0
    const failedFiles = []

    // Iterate over each file and delete it
    for (const docSnapshot of querySnapshot.docs) {
      const fileData = docSnapshot.data()
      const fileId = docSnapshot.id
      console.log(`Processing file ${fileId}: ${JSON.stringify(fileData)}`)

      try {
        // Delete file from storage
        if (fileData.name) {
          const storagePath = `userFiles/${userId}/${folderId}/${fileData.name}`
          console.log(`Deleting file from storage: ${storagePath}`)
          try {
            const fileRef = ref(storage, storagePath)
            await deleteObject(fileRef)
            console.log(`Successfully deleted file from storage: ${storagePath}`)
          } catch (error) {
            console.error(`Error deleting file from storage: ${storagePath}`, error)
            failedFiles.push({ id: fileId, path: storagePath, error: error.message })
          }
        }

        if (fileData.paragraphs_blob_path) {
          console.log(`Deleting paragraphs blob: ${fileData.paragraphs_blob_path}`)
          try {
            const paragraphsBlobRef = ref(storage, fileData.paragraphs_blob_path)
            await deleteObject(paragraphsBlobRef)
            console.log(`Successfully deleted paragraphs blob: ${fileData.paragraphs_blob_path}`)
          } catch (error) {
            console.error(`Error deleting paragraphs blob: ${fileData.paragraphs_blob_path}`, error)
            failedFiles.push({ id: fileId, path: fileData.paragraphs_blob_path, error: error.message })
          }
        }

        if (fileData.presidio_entities_blob_path) {
          console.log(`Deleting entities blob: ${fileData.presidio_entities_blob_path}`)
          try {
            const entitiesBlobRef = ref(storage, fileData.presidio_entities_blob_path)
            await deleteObject(entitiesBlobRef)
            console.log(`Successfully deleted entities blob: ${fileData.presidio_entities_blob_path}`)
          } catch (error) {
            console.error(`Error deleting entities blob: ${fileData.presidio_entities_blob_path}`, error)
            failedFiles.push({ id: fileId, path: fileData.presidio_entities_blob_path, error: error.message })
          }
        }

        // Delete file document from Firestore
        console.log(`Deleting file document from Firestore: files/${fileId}`)
        await deleteDoc(doc(db, 'files', fileId))
        console.log(`Successfully deleted file document: files/${fileId}`)

        count++
        setDeletedFilesCount(prev => prev + 1)
      } catch (error) {
        console.error(`Error deleting file ${fileId}:`, error)
        failedFiles.push({ id: fileId, error: error.message })
      }
    }

    if (failedFiles.length > 0) {
      console.error(`Failed to delete ${failedFiles.length} files:`, failedFiles)
    }

    console.log(`Deleted ${count} files from folder ${folderId}`)
  }

  const deleteFolder = async (folderId) => {
    console.log(`Starting deletion of folder ${folderId}`)
    // Recursively delete subfolders and their files
    setIsDeleting(true) // Start deletion
    setDeletedFilesCount(0) // Reset deleted files count

    try {
      console.log(`Querying subfolders of folder ${folderId}`)
      const subfoldersQuery = query(collection(db, 'folders'), where('parent_id', '==', folderId))
      const subfolderSnapshots = await getDocs(subfoldersQuery)
      console.log(`Found ${subfolderSnapshots.docs.length} subfolders in folder ${folderId}`)

      for (const subfolder of subfolderSnapshots.docs) {
        console.log(`Processing subfolder: ${subfolder.id}`)
        await deleteFolder(subfolder.id)
      }

      // Delete files in the current folder
      console.log(`Deleting files in folder ${folderId}`)
      await deleteFilesInFolder(folderId)

      // Delete the folder document itself
      console.log(`Deleting folder document from Firestore: folders/${folderId}`)
      await deleteDoc(doc(db, 'folders', folderId))
      console.log(`Successfully deleted folder document: folders/${folderId}`)
    } catch (error) {
      console.error(`Error in deleteFolder for folderId ${folderId}:`, error)
      setErrorMessage(`Fehler beim Löschen: ${error.message}`)
    } finally {
      setIsDeleting(false) // End deletion
    }
  }

  const confirmDeleteFolder = async () => {
    console.log(`Confirming deletion of folder: ${folderToDelete}`)
    if (!folderToDelete) {
      console.error('No folder selected for deletion')
      return
    }

    // get the folder document
    const folderDocumentRef = doc(db, 'folders', folderToDelete)
    console.log(`Fetching folder document: folders/${folderToDelete}`)
    const folderDocumentSnapshot = await getDoc(folderDocumentRef)

    if (!folderDocumentSnapshot.exists()) {
      console.error(`Folder document does not exist: folders/${folderToDelete}`)
      setDeleteFolderDialogOpen(false)
      return
    }

    const folderData = folderDocumentSnapshot.data()
    console.log(`Folder data: ${JSON.stringify(folderData)}`)

    if (folderData.name === 'Root') {
      console.log('Attempted to delete Root folder - operation canceled')
      setDeleteFolderDialogOpen(false)
      setErrorMessage('"Root" Ordner kann nicht gelöscht werden.')
      setDeleteFolderDialogOpen(true)
      return
    }

    try {
      console.log(`Starting folder deletion process for ${folderToDelete}`)
      await deleteFolder(folderToDelete)
      console.log(`Folder deletion completed for ${folderToDelete}`)
      refreshFolderTree()
    } catch (error) {
      console.error('Error deleting folder:', error)
      setErrorMessage(`Fehler beim Löschen des Ordners: ${error.message}`)
    } finally {
      setDeleteFolderDialogOpen(false)
      setFolderToDelete(null)
    }
  }

  const DeleteFolderDialog = (
        <Dialog
            open={deleteFolderDialogOpen}
            onClose={(event, reason) => {
              if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                setDeleteFolderDialogOpen(false)
                setErrorMessage('')
                setIsDeleting(false)
              }
            }}
        >
            <DialogTitle>Ordner löschen</DialogTitle>
            <DialogContent>
                <Typography color="error">{errorMessage}</Typography>
                {isDeleting && (
                    <>
                        <CircularProgress />
                        <Typography variant="body2" style={{ marginTop: 10 }}>
                            Anzahl gelöschter Dateien: {deletedFilesCount}
                        </Typography>
                    </>
                )}
                <p>Sind Sie sicher, dass Sie den Ordner &quot;{folderName}&quot; löschen wollen?</p> {/* Include the folder name */}
            </DialogContent>
            <DialogActions>
                <Button
                    className="preview-btn action"
                    onClick={() => { setDeleteFolderDialogOpen(false); setErrorMessage(''); setIsDeleting(false) }}
                    color="primary"
                    disabled={isDeleting}
                    label="Abbrechen"
                    isCTA={!isDeleting}
                />
                <Button
                    className="preview-btn action"
                    onClick={confirmDeleteFolder}
                    color="secondary"
                    disabled={isDeleting}
                    label="Löschen"
                    isCTA={!isDeleting}
                />
            </DialogActions>
        </Dialog>
  )

  return { handleDeleteFolderClick, DeleteFolderDialog }
}

export default useDeleteFolderLogic
