import React, { useState, useEffect, useRef } from 'react'
import { Card, CardContent, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '../Button/Button'
import { useSelector } from 'react-redux'
import styles from './PlaygroundLanding.module.scss'

export default function PlaygroundLanding () {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
    const user = useSelector(state => state.auth.userId)
    const card1Ref = useRef()
    const card2Ref = useRef()
    const [maxHeight, setMaxHeight] = useState('auto')
    const headerHeight = useSelector((state) => state.layout.headerHeight)
    const footerHeight = useSelector((state) => state.layout.footerHeight)
    const teamHeight = `calc(100vh - ${footerHeight}px - ${headerHeight}px)`
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768)
            if (card1Ref.current && card2Ref.current) {
                setMaxHeight(Math.max(card1Ref.current.offsetHeight, card2Ref.current.offsetHeight))
            }
        }
        window.addEventListener('resize', handleResize)
        handleResize() // Ensure maxHeight is set initially
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const handleButtonClick = (path) => {
        if (typeof path === 'string') {
            navigate(path)
        } else {
            navigate(path.pathname, { state: path.state })
        }
    }
    const isUserLoggedIn = user != null

    const buttonProps = (baseLabel, targetPath) => ({
        label: baseLabel,
        slug: targetPath,
        disabled: isMobile,
        isCTA: !isMobile,
        onClick: () => handleButtonClick(targetPath)
    })

    const buttonLabel = (baseLabel) => {
        if (isMobile) {
            return 'Nur auf Desktop verfügbar'
        } else if (!isUserLoggedIn) {
            return 'Anmelden, um fortzufahren'
        } else {
            return baseLabel
        }
    }

    return (
        <section className={styles.team} style={{ marginTop: `calc(${headerHeight}px)`, paddingTop: '2rem' }}>
            <h2>Unsere Lösungen bei Elephant Labs</h2>
            <p>Entdecken Sie die innovativen KI-Module</p>
            <Card style={{ height: maxHeight }} ref={card1Ref}>
                <CardContent className={styles.customCardContent}>
                    <Typography variant="h6">Anonymisierung rechtlicher Dokumente</Typography>
                    <Typography variant="body1">
                        Nutze das Anonymisierungs-Modul für die Anonymisierung von Dokumenten.
                    </Typography>
                    <Button {...buttonProps(buttonLabel('Jetzt ausprobieren →'), user ? '/playground/anonymizationPWA' : {
                        pathname: '/signin',
                        state: { from: location.pathname }
                    })}/>
                    {/* <Button
                        className={`${isMobile || !isUserLoggedIn ? styles.disabled : styles.action}`}
                        label={'Jetzt ausprobieren →'}
                        isCTA={true}
                        // disabled
                    /> */}
                </CardContent>
            </Card>
            <h5>🔒 Ihre Daten werden direkt auf Ihrem Endgerät verschlüsselt, bevor sie übermittelt werden. Eine Nutzung zur Verbesserung der KI-Modelle von Elephant Labs ist ausgeschlossen.</h5>
        </section>
    )
}
