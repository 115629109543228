import { API_ENDPOINTS } from '../../../../config/apiConfig'
import store from '../../../../store'
import { refreshToken } from '../../../../slices/authSlice'

const anonymizePDF = async ({
                              fileId,
                              bearerToken,
                              redactImages = true,
                              isRetry = false
                            }) => {
  if (!fileId) {
    throw new Error('File ID is required')
  }

  if (!bearerToken) {
    throw new Error('Bearer token is required for authentication')
  }

  // Using relative URL for proxy
  const url = new URL(API_ENDPOINTS.fileAnonymizer, window.location.origin)

  // Create request body instead of using query params
  const requestBody = {
    file_id: fileId,
    redact_images: redactImages,
    store_anonymization: true
  }

  // console.log(bearerToken)
  // console.log(fileId)

  // console.log('Making request to:', url.toString().replace(fileId, '[REDACTED_FILE_ID]'))

  try {
    const response = await fetch(url.toString(), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    })

    // Handle non-JSON responses
    const contentType = response.headers.get('content-type')
    let data
    if (contentType && contentType.includes('application/json')) {
      data = await response.json()
    } else {
      const text = await response.text()
      data = { error: text }
    }

    // Handle authentication failure - refresh token and retry once
    if (response.status === 401 && !isRetry) {
      console.log('Token expired, attempting to refresh...')
      try {
        // Dispatch the refreshToken action and wait for it to complete
        const refreshResult = await store.dispatch(refreshToken()).unwrap()
        const newToken = refreshResult.userIdToken

        // Retry the request with the new token
        return anonymizePDF({
          fileId,
          bearerToken: newToken,
          redactImages,
          isRetry: true // Prevent infinite retry loops
        })
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError)
        throw new Error('Session expired. Please sign in again.')
      }
    }

    if (!response.ok) {
      const errorMessage = data.detail || data.error || data.message || 'An error occurred'
      throw new Error(errorMessage)
    }
    // console.log('Anonymization response:', data)

    return data
  } catch (error) {
    console.error('PDF anonymization failed:', error)
    throw error
  }
}

export default anonymizePDF
