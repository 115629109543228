import { getDownloadURL, ref } from 'firebase/storage'
import { db, storage } from '../../../../firebase/firebase'
import { doc, getDoc } from 'firebase/firestore'

async function downloadAnonymizations (userId, fileId) {
  const fileDocumentRef = doc(db, 'files', fileId)
  const fileDocumentSnapshot = await getDoc(fileDocumentRef)
  if (!fileDocumentSnapshot.exists) {
    console.log('No such document!')
    return {}
  }
  const fileDocument = fileDocumentSnapshot.data()

  const fileRef = ref(storage, fileDocument.anonymized_entities)

  try {
    const fileUrl = await getDownloadURL(fileRef)
    const response = await fetch(fileUrl)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    const result = await response.json()
    return result
  } catch (error) {
    if (error.code === 'storage/object-not-found') {
      // Handle the "not found" error gracefully here
      console.log('File does not exist, no error thrown.')
      return {}
    } else {
      console.error('Error downloading anonymizations: ', error)
      throw error // Or handle other types of errors as you see fit
    }
  }
}

export { downloadAnonymizations }
