import React, { useState, useEffect, useRef } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  IconButton,
  Tooltip,
  Box,
  Alert,
  Divider,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Button as MuiButton,
  Collapse
} from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RefreshIcon from '@mui/icons-material/Refresh'
import WarningIcon from '@mui/icons-material/Warning'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import ReplayIcon from '@mui/icons-material/Replay'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore'
import { db } from '../../../../../../firebase/firebase'
import Button from '../../../../../../components/Button/Button'
import anonymizePDF from '../../../ServerLogics/anonymizeBackend'
import SecureFileStatusManager from '../../../Logics/SecureFileStatusManager'
import { useDispatch, useSelector } from 'react-redux'
import { updateAnonymizationMapping } from '../../../../../../slices/FolderTreeDataStateSlice'
import { useBatchOperationStyles, getFileListItemStyle, getStatusColor } from './BatchOperationsStyles'

function useBatchAnonymizationLogic (userId, folderId, refreshFolderTree, setContextMenu) {
  const styles = useBatchOperationStyles()

  // Config constants
  const CONCURRENT_PROCESS_LIMIT = 3 // Maximum number of concurrent anonymizations
  const MAX_AUTO_RETRIES = 3 // Maximum number of automatic retries for failed files

  const [batchAnonymizationDialogOpen, setBatchAnonymizationDialogOpen] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [overallProgress, setOverallProgress] = useState(0)
  const [files, setFiles] = useState([])
  const [fileStatuses, setFileStatuses] = useState({})
  const [alreadyAnonymizedFiles, setAlreadyAnonymizedFiles] = useState([])
  const [isClosingBlocked, setIsClosingBlocked] = useState(false)
  const [anonymizationStats, setAnonymizationStats] = useState({ total: 0, completed: 0, errors: 0, skipped: 0 })
  const [showRefreshWarning, setShowRefreshWarning] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [anonymizeImages, setAnonymizeImages] = useState(true)
  const [noFiles, setNoFiles] = useState(false)
  const [expandedErrors, setExpandedErrors] = useState({})
  const [isReprocessing, setIsReprocessing] = useState(false)
  const [showErrorSummary, setShowErrorSummary] = useState(false)

  // Refs for processing queue management
  const processQueueRef = useRef([]) // Queue of file indices to process
  const activeProcessesRef = useRef(0) // Count of active processes

  const dispatch = useDispatch()
  const userIdToken = useSelector((state) => state.auth.userIdToken)

  // Prevent window closing during processing
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (processing) {
        // Show custom warning message
        setShowRefreshWarning(true)

        // Standard behavior to prevent closing
        e.preventDefault()
        e.returnValue = 'Anonymisierung läuft noch. Wenn Sie jetzt die Seite verlassen, wird die Anonymisierung unterbrochen.'
        return e.returnValue
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [processing])

  // Update progress immediately when stats change
  useEffect(() => {
    if (files.length > 0) {
      // Calculate progress based on completed, error, and skipped files
      const processedFiles = anonymizationStats.completed + anonymizationStats.errors + anonymizationStats.skipped

      // Calculate the weighted progress
      let overallPercentValue
      if (files.length === processedFiles) {
        // If all files are completed/errored/skipped, set to 100%
        overallPercentValue = 100
      } else {
        // Calculate total progress, giving appropriate weight to each file
        const totalProgressValue = ((processedFiles * 100) +
          // Add progress of files that are currently being processed
          Object.values(fileStatuses)
            .filter(status => status.status === 'processing')
            .reduce((sum, status) => sum + (status.progress || 0), 0)) / files.length

        overallPercentValue = Math.min(99, totalProgressValue)

        // Ensure progress never goes backward
        overallPercentValue = Math.max(overallPercentValue, overallProgress)
      }

      // Only update if the change is significant
      if (Math.abs(overallPercentValue - overallProgress) > 0.5) {
        setOverallProgress(overallPercentValue)
      }
    }
  }, [anonymizationStats, fileStatuses, files.length, overallProgress])

  const handleBatchAnonymizationClick = async () => {
    setContextMenu(null)
    setBatchAnonymizationDialogOpen(true)
    setFiles([])
    setFileStatuses({})
    setAlreadyAnonymizedFiles([])
    setOverallProgress(0)
    setIsClosingBlocked(false)
    setProcessing(false)
    setIsReprocessing(false)
    setShowSuccessMessage(false)
    setAnonymizationStats({ total: 0, completed: 0, errors: 0, skipped: 0 })
    setNoFiles(false)

    // Reset queue refs
    processQueueRef.current = []
    activeProcessesRef.current = 0

    // Fetch PDF files in the selected folder
    try {
      const filesQuery = query(
        collection(db, 'files'),
        where('parent_id', '==', folderId),
        where('user_id', '==', userId)
      )

      const filesSnapshot = await getDocs(filesQuery)
      const pdfFiles = []

      filesSnapshot.forEach(doc => {
        const fileData = doc.data()
        // Only include PDF files
        if (fileData.name.toLowerCase().endsWith('.pdf')) {
          pdfFiles.push({
            id: doc.id,
            ...fileData
          })
        }
      })

      if (pdfFiles.length === 0) {
        setNoFiles(true)
      } else {
        setFiles(pdfFiles)
        setAnonymizationStats({ total: pdfFiles.length, completed: 0, errors: 0, skipped: 0 })

        // Check which files are already anonymized
        const anonymizedFileNames = []
        for (const file of pdfFiles) {
          if (file.anonymized_entities) {
            anonymizedFileNames.push(file.name)
          }
        }

        if (anonymizedFileNames.length > 0) {
          setAlreadyAnonymizedFiles(anonymizedFileNames)
        }
      }
    } catch (error) {
      console.error('Error fetching files:', error)
    }
  }

  const getFileStatusById = (fileId) => {
    const index = files.findIndex(file => file.id === fileId)
    return { index, status: fileStatuses[index] }
  }

  // Helper function to categorize errors
  const categorizeError = (error) => {
    // Ensure we have a string error message
    const errorMessage = typeof error.message === 'string' ? error.message : (typeof error === 'string' ? error : 'Unbekannter Fehler')

    // For specific object errors that are causing issues, make them non-retryable
    // This prevents the infinite retry loop for these types of errors
    if (typeof error === 'object' && error !== null && (!error.message || error.message === undefined || error.message === null)) {
      return {
        type: 'object_error',
        message: 'Objektfehler',
        isRetryable: false,
        recommendation: 'Ein unbekannter Fehler ist aufgetreten.'
      }
    }

    // Extract status code if available in the error object or message
    let statusCode = null
    if (error.status) {
      statusCode = error.status
    } else if (typeof errorMessage === 'string') {
      // Try to extract status code from error message if it contains something like "404" or "status: 429"
      const statusMatch = errorMessage.match(/\b(400|401|404|422|429|500)\b/)
      if (statusMatch) {
        statusCode = parseInt(statusMatch[1], 10)
      }
    }

    // Handle specific API error status codes from documentation
    switch (statusCode) {
      case 400:
        return {
          type: 'invalid_input',
          message: 'Ungültige Eingabeparameter',
          isRetryable: false,
          recommendation: 'Bitte überprüfen Sie die Datei-ID.'
        }

      case 401:
        return {
          type: 'auth',
          message: 'Authentifizierungsfehler',
          isRetryable: true, // Retry once with token refresh
          recommendation: 'Die Authentifizierung ist abgelaufen. Wir versuchen, Ihre Sitzung zu aktualisieren.'
        }

      case 404:
        return {
          type: 'not_found',
          message: 'Datei nicht gefunden',
          isRetryable: false,
          recommendation: 'Die angeforderte Datei wurde nicht auf dem Server gefunden. Bitte laden Sie die Datei erneut hoch.'
        }

      case 422:
        return {
          type: 'validation',
          message: 'Validierungsfehler',
          isRetryable: false,
          recommendation: 'Das Format der Datei wird möglicherweise nicht unterstützt.'
        }

      case 429:
        return {
          type: 'rate_limit',
          message: 'Ratenbegrenzung überschritten',
          isRetryable: true, // Can retry after waiting
          recommendation: 'Zu viele Anfragen. Wir werden es später erneut versuchen.',
          retryDelay: 5000 // Wait 5 seconds before retry
        }

      case 500:
        return {
          type: 'server_error',
          message: 'Serverfehler',
          isRetryable: true,
          recommendation: 'Es ist ein Fehler auf dem Server aufgetreten. Wir versuchen es erneut.',
          retryDelay: 3000 // Wait 3 seconds before retry
        }
    }

    // If no specific status code, try to categorize based on message content
    if (errorMessage.includes('network') ||
        errorMessage.includes('timeout') ||
        errorMessage.includes('connection')) {
      return {
        type: 'network',
        message: 'Netzwerkfehler bei der Verbindung zum Server',
        isRetryable: true,
        recommendation: 'Bitte überprüfen Sie Ihre Internetverbindung.'
      }
    }

    if (errorMessage.toLowerCase().includes('token') ||
        errorMessage.toLowerCase().includes('auth')) {
      return {
        type: 'auth',
        message: 'Authentifizierungsfehler',
        isRetryable: true,
        recommendation: 'Ihre Sitzung ist möglicherweise abgelaufen. Wir versuchen, Sie erneut anzumelden.'
      }
    }

    if (errorMessage.toLowerCase().includes('limit') ||
        errorMessage.toLowerCase().includes('quota') ||
        errorMessage.includes('exceeded')) {
      return {
        type: 'rate_limit',
        message: 'Ratenbegrenzung oder Kontingent überschritten',
        isRetryable: false,
        recommendation: 'Tagesgrenze erreicht. Bitte versuchen Sie es morgen erneut oder kontaktieren Sie uns für höhere Limits.'
      }
    }

    // Default case - generic error
    return {
      type: 'general',
      message: errorMessage,
      isRetryable: true,
      recommendation: 'Ein unerwarteter Fehler ist aufgetreten. Versuchen Sie es erneut oder kontaktieren Sie den Support.'
    }
  }

  const toggleErrorDetails = (index) => {
    setExpandedErrors(prev => ({
      ...prev,
      [index]: !prev[index]
    }))
  }

  const processFile = async (file, index) => {
    // If file is already anonymized, skip it
    if (file.anonymized_entities) {
      // Mark file as skipped
      setFileStatuses(prev => ({
        ...prev,
        [index]: { status: 'skipped', progress: 0 }
      }))

      // Update stats for skipped file
      setAnonymizationStats(prev => ({
        ...prev,
        skipped: prev.skipped + 1
      }))

      return false
    }

    // SIMPLER APPROACH: Use a for loop with fixed number of attempts instead of recursion
    let attempt = 1
    const maxAttempts = 3

    while (attempt <= maxAttempts) {
      try {
        // Show the current attempt in the UI
        setFileStatuses(prev => ({
          ...prev,
          [index]: attempt > 1 ? { status: 'retrying', progress: 0, retryCount: attempt } : { status: 'processing', progress: 10 }
        }))

        // Set the file as busy
        await SecureFileStatusManager.setFileBusy(userId, file.id)

        // Update progress
        setFileStatuses(prev => ({
          ...prev,
          [index]: {
            status: attempt > 1 ? 'retrying' : 'processing',
            progress: 30,
            retryCount: attempt > 1 ? attempt : undefined
          }
        }))

        // Call the anonymization API
        const result = await anonymizePDF({
          fileId: file.id,
          bearerToken: userIdToken,
          redactImages: anonymizeImages
        })

        // Update progress
        setFileStatuses(prev => ({
          ...prev,
          [index]: {
            status: attempt > 1 ? 'retrying' : 'processing',
            progress: 80,
            retryCount: attempt > 1 ? attempt : undefined
          }
        }))

        // Update Redux with the new anonymization data
        dispatch(updateAnonymizationMapping({
          fileId: file.id,
          newAnonymization: result
        }))

        // Mark the file as completed
        setFileStatuses(prev => ({
          ...prev,
          [index]: { status: 'completed', progress: 100 }
        }))

        // Update stats for completed file
        setAnonymizationStats(prev => ({
          ...prev,
          completed: prev.completed + 1
        }))

        // Success - exit the retry loop
        return true
      } catch (error) {
        console.error(`Error anonymizing file ${file.name} (attempt ${attempt}/${maxAttempts}):`, error)

        // Clear busy status before deciding what to do next
        try {
          await SecureFileStatusManager.clearFileBusy(userId, file.id)
        } catch (e) {
          console.error(`Error clearing busy status for ${file.name}:`, e)
        }

        // Determine if error is retryable
        const errorInfo = categorizeError(error)
        const canRetry = errorInfo.isRetryable && attempt < maxAttempts

        if (canRetry) {
          // We'll retry - wait before next attempt
          const delay = errorInfo.retryDelay || 2000
          console.log(`Retry ${attempt + 1}/${maxAttempts} for ${file.name} in ${delay}ms`)

          // Wait before retrying
          await new Promise(resolve => setTimeout(resolve, delay))

          // Increment attempt for next iteration
          attempt++
        } else {
          // No more retries - mark as error
          setFileStatuses(prev => ({
            ...prev,
            [index]: {
              status: 'error',
              error: errorInfo.message,
              errorDetail: typeof error.message === 'string' ? error.message : 'Unbekannter Fehler',
              recommendation: errorInfo.recommendation,
              errorType: errorInfo.type,
              retryCount: attempt - 1 // Show number of attempts made
            }
          }))

          // Update stats for error
          setAnonymizationStats(prev => ({
            ...prev,
            errors: prev.errors + 1
          }))

          // Exit the retry loop with failure
          return false
        }
      }
    }

    // If we somehow got here (shouldn't happen), mark as error
    setFileStatuses(prev => ({
      ...prev,
      [index]: {
        status: 'error',
        error: 'Maximale Anzahl an Wiederholungen erreicht',
        errorDetail: 'Der Anonymisierungsprozess wurde nach mehreren Versuchen abgebrochen.',
        retryCount: maxAttempts
      }
    }))

    // Update stats for error
    setAnonymizationStats(prev => ({
      ...prev,
      errors: prev.errors + 1
    }))

    return false
  }

  const retryFile = async (index) => {
    if (!files[index]) return

    // Update status to processing
    setFileStatuses(prev => ({
      ...prev,
      [index]: { status: 'processing', progress: 0 }
    }))

    // Update stats for retry
    if (fileStatuses[index]?.status === 'error') {
      setAnonymizationStats(prev => ({
        ...prev,
        errors: Math.max(0, prev.errors - 1)
      }))
    }

    // Process the file directly (not through queue since it's a single file)
    await processFile(files[index], index)
  }

  const retryAllFailed = async () => {
    setIsReprocessing(true)

    try {
      // Find all files with error status
      const failedFileIndexes = Object.entries(fileStatuses)
        .filter(([_, status]) => status.status === 'error' && status.isRetryable !== false)
        .map(([index]) => parseInt(index))

      // Add failed files to the queue for parallel processing
      processQueueRef.current = [...failedFileIndexes]
      activeProcessesRef.current = 0

      // Process the queue
      processQueue()
    } catch (error) {
      console.error('Error retrying failed files:', error)
      setIsReprocessing(false)
      refreshFolderTree()
    }
  }

  // Process the queue with concurrent anonymizations
  const processQueue = () => {
    // Keep starting new processes until we reach the limit or run out of files
    while (processQueueRef.current.length > 0 && activeProcessesRef.current < CONCURRENT_PROCESS_LIMIT) {
      const fileIndex = processQueueRef.current.shift()
      activeProcessesRef.current++

      // If we're retrying a failed file, reset its status
      if (fileStatuses[fileIndex]?.status === 'error') {
        // Update stats for retry
        setAnonymizationStats(prev => ({
          ...prev,
          errors: Math.max(0, prev.errors - 1)
        }))
      }

      // Update status to processing
      setFileStatuses(prev => ({
        ...prev,
        [fileIndex]: { status: 'processing', progress: 0 }
      }))

      // Start processing this file asynchronously
      processFile(files[fileIndex], fileIndex)
        .finally(() => {
          // When processing completes (success or error), decrease active count and process more
          activeProcessesRef.current--
          processQueue()

          // Check if we're completely done
          if (processQueueRef.current.length === 0 && activeProcessesRef.current === 0) {
            handleProcessingComplete()
          }
        })
    }
  }

  const handleProcessingComplete = () => {
    // Processing complete - update UI states
    setProcessing(false)
    setIsReprocessing(false)
    setIsClosingBlocked(false)

    // Check if anonymization was successful for all files
    if (anonymizationStats.errors === 0) {
      setShowSuccessMessage(true)
    }

    // Refresh folder tree to ensure UI consistency
    refreshFolderTree()
  }

  const handleAnonymizeFiles = async () => {
    if (files.length === 0) return

    setProcessing(true)
    setIsClosingBlocked(true)
    setShowSuccessMessage(false)

    try {
      // Prepare the queue with files that need processing
      processQueueRef.current = files
        .map((_, index) => index)
        .filter(index => !(
          fileStatuses[index]?.status === 'completed' ||
          fileStatuses[index]?.status === 'skipped'
        ))

      activeProcessesRef.current = 0

      // Start processing the queue
      processQueue()
    } catch (error) {
      console.error('Batch anonymization error:', error)
      setProcessing(false)
      setIsClosingBlocked(false)
    }
  }

  // Add a helper function to handle close button clicks
  const handleCloseButtonClick = () => {
    if (isClosingBlocked) {
      // Show warning if anonymization is running
      setShowRefreshWarning(true)
    } else {
      handleClose()
    }
  }

  const handleClose = () => {
    // Only allow closing if not currently processing
    if (!isClosingBlocked) {
      setBatchAnonymizationDialogOpen(false)

      // Check if any files were anonymized successfully before resetting
      const anyFilesProcessed = anonymizationStats.completed > 0

      // Reset states
      setFiles([])
      setFileStatuses({})
      setAlreadyAnonymizedFiles([])
      setOverallProgress(0)
      setAnonymizationStats({ total: 0, completed: 0, errors: 0, skipped: 0 })
      setShowSuccessMessage(false)
      setNoFiles(false)

      // Refresh folder tree if any files were anonymized
      if (anyFilesProcessed) {
        refreshFolderTree()
      }
    }
  }

  const getStatusIcon = (status) => {
    switch (status) {
      case 'completed':
        return <CheckCircleIcon sx={{ color: getStatusColor('completed') }} />
      case 'error':
        return <ErrorIcon sx={{ color: getStatusColor('error') }} />
      case 'skipped':
        return <WarningIcon sx={{ color: getStatusColor('skipped') }} />
      case 'processing':
        return <CircularProgress size={20} sx={{ color: getStatusColor('processing') }} />
      case 'retrying':
        return <RefreshIcon sx={{ color: getStatusColor('processing') }} />
      case 'pending':
        return <HourglassEmptyIcon sx={{ color: getStatusColor('processing'), opacity: 0.7 }} />
      default:
        return null
    }
  }

  const getStatusText = (file, index) => {
    const status = fileStatuses[index]?.status

    if (!status) return 'Warten'

    switch (status) {
      case 'processing':
        return `Wird anonymisiert (${Math.round(fileStatuses[index]?.progress || 0)}%)`
      case 'retrying':
        // Show simple retry count, explicitly show which attempt we're on
        return `Versuch ${fileStatuses[index]?.retryCount}/3`
      case 'completed':
        return 'Anonymisierung abgeschlossen'
      case 'error':
        return `Fehler: ${fileStatuses[index]?.error || 'Unbekannter Fehler'}`
      case 'skipped':
        return 'Bereits anonymisiert'
      default:
        return status
    }
  }

  const allProcessed = files.length > 0 &&
    anonymizationStats.completed + anonymizationStats.errors + anonymizationStats.skipped === files.length

  // Check if there are any failed files that can be retried
  const hasRetryableFailures = Object.values(fileStatuses)
    .some(status => status.status === 'error' && status.isRetryable !== false)

  // Function to generate error summary for failed files
  const generateErrorSummary = () => {
    if (!hasRetryableFailures && anonymizationStats.errors === 0) return null

    // Group errors by type
    const errorGroups = {}

    Object.entries(fileStatuses).forEach(([index, status]) => {
      if (status.status === 'error') {
        const errorType = status.errorType || 'general'
        if (!errorGroups[errorType]) {
          errorGroups[errorType] = {
            count: 0,
            files: [],
            message: status.error,
            recommendation: status.recommendation
          }
        }
        errorGroups[errorType].count++
        errorGroups[errorType].files.push(files[index].name)
      }
    })

    return (
      <>
        {Object.entries(errorGroups).map(([errorType, data], i) => (
          <Box key={errorType} sx={{ mb: i < Object.keys(errorGroups).length - 1 ? 2 : 0 }}>
            <Typography variant="subtitle2" fontWeight="bold">
              {data.message} ({data.count} {data.count === 1 ? 'Datei' : 'Dateien'})
            </Typography>

            {data.recommendation && (
              <Typography variant="body2" sx={{ mt: 0.5, mb: 1 }}>
                {data.recommendation}
              </Typography>
            )}

            <Box component="ul" sx={{ m: 0, pl: 2, mb: 1 }}>
              {data.files.slice(0, 5).map((filename, i) => (
                <li key={i}>
                  <Typography variant="caption">{filename}</Typography>
                </li>
              ))}
              {data.files.length > 5 && (
                <Typography variant="caption">
                  ... und {data.files.length - 5} weitere
                </Typography>
              )}
            </Box>
          </Box>
        ))}
      </>
    )
  }

  // Function to display statistics for large file sets
  const LargeFileSetStats = () => {
    if (files.length < 20) return null // Only show for larger file sets

    const pendingCount = files.length -
      (anonymizationStats.completed + anonymizationStats.errors + anonymizationStats.skipped) -
      Object.values(fileStatuses).filter(s => s?.status === 'processing' || s?.status === 'retrying').length

    const processingCount = Object.values(fileStatuses).filter(
      s => s?.status === 'processing' || s?.status === 'retrying'
    ).length

    return (
      <Box sx={{ mt: 2, mb: 1, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
        <Typography variant="subtitle2" gutterBottom>
          Anonymisierungs-Status für {files.length} Dateien:
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', mb: 1 }}>
          <Typography variant="body2">
            <strong>Warten:</strong> {pendingCount}
          </Typography>
          <Typography variant="body2">
            <strong>In Bearbeitung:</strong> {processingCount}
          </Typography>
          <Typography variant="body2">
            <strong>Abgeschlossen:</strong> {anonymizationStats.completed}
          </Typography>
          <Typography variant="body2">
            <strong>Fehler:</strong> {anonymizationStats.errors}
          </Typography>
          <Typography variant="body2">
            <strong>Übersprungen:</strong> {anonymizationStats.skipped}
          </Typography>
        </Box>
        {(processing || isReprocessing) && (
          <Typography variant="caption" sx={{ display: 'block', mt: 1, color: 'text.secondary' }}>
            Die Dateien werden in Batches von {CONCURRENT_PROCESS_LIMIT} gleichzeitig anonymisiert, um die Serverressourcen zu schonen.
          </Typography>
        )}
      </Box>
    )
  }

  const BatchAnonymizationDialog = (
    <>
      <Dialog
        open={batchAnonymizationDialogOpen}
        onClose={(event, reason) => {
          // Only handle non-disruptive close events
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleCloseButtonClick()
          } else if (isClosingBlocked) {
            setShowRefreshWarning(true)
          }
        }}
        maxWidth="md"
        fullWidth
        disableEscapeKeyDown={isClosingBlocked}
      >
        {(processing || isReprocessing) && <LinearProgress variant="determinate" value={overallProgress} className={styles.overallProgress} />}
        <DialogTitle className={styles.dialogTitle}>
          Batch Anonymisierung
          {processing && (
            <Typography variant="subtitle2" color="textSecondary" className="subtitle">
              Bitte Dialog nicht schließen. Anonymisierung läuft...
            </Typography>
          )}
          {isReprocessing && (
            <Typography variant="subtitle2" color="textSecondary" className="subtitle">
              Fehlgeschlagene Dateien werden erneut verarbeitet...
            </Typography>
          )}
          {allProcessed && !processing && !isReprocessing && (
            <>
              <Typography variant="subtitle2" className={styles.successMessage}>
                Anonymisierung abgeschlossen!
              </Typography>
              {anonymizationStats.errors > 0 && (
                <Typography variant="caption" color="error" sx={{ display: 'block', mt: 1 }}>
                  {anonymizationStats.errors} Dateien konnten nicht anonymisiert werden.
                </Typography>
              )}
            </>
          )}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Alle PDF-Dateien im ausgewählten Ordner anonymisieren
          </Typography>

          {noFiles ? (
            <Alert severity="info" style={{ marginTop: '20px' }}>
              Keine PDF-Dateien im ausgewählten Ordner gefunden.
            </Alert>
          ) : (
            <>
              <Box sx={{ mt: 3, mb: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={anonymizeImages}
                      onChange={(e) => setAnonymizeImages(e.target.checked)}
                      disabled={processing || isReprocessing || allProcessed}
                    />
                  }
                  label="Bilddaten (Unterschriften, Logos, etc.) anonymisieren"
                />
              </Box>

              {files.length > 0 && (
                <>
                  <Box className={styles.progressContainer}>
                    <Box className={styles.statusBox} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Gesamtfortschritt: {Math.round(overallProgress)}%
                      </Typography>
                      <Typography variant="body2">
                        {anonymizationStats.completed} von {files.length} abgeschlossen
                        {processing || isReprocessing ? ' (Läuft...)' : allProcessed ? ' (Abgeschlossen)' : ' (Bereit)'}
                      </Typography>
                    </Box>
                  </Box>

                  <Divider style={{ margin: '12px 0' }} />

                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Dateien ({files.length})
                    </Typography>

                    <Box sx={{ display: 'flex', gap: 2 }} className="status-summary">
                      {anonymizationStats.completed > 0 && (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }} className="status-badge">
                          <CheckCircleIcon fontSize="small" sx={{ color: getStatusColor('completed') }} />
                          <Typography variant="body2">{anonymizationStats.completed}</Typography>
                        </Box>
                      )}

                      {anonymizationStats.errors > 0 && (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }} className="status-badge">
                          <ErrorIcon fontSize="small" sx={{ color: getStatusColor('error') }} />
                          <Typography variant="body2">{anonymizationStats.errors}</Typography>
                        </Box>
                      )}

                      {anonymizationStats.skipped > 0 && (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }} className="status-badge">
                          <WarningIcon fontSize="small" sx={{ color: getStatusColor('skipped') }} />
                          <Typography variant="body2">{anonymizationStats.skipped}</Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>

                  <Paper elevation={1} className={styles.fileStatusPaper}>
                    <List dense disablePadding>
                      {files.map((file, index) => (
                        <ListItem
                          key={`${file.id}-${index}`}
                          sx={getFileListItemStyle(fileStatuses[index]?.status || 'pending')}
                          secondaryAction={
                            !processing && !isReprocessing && fileStatuses[index]?.status === 'error' && fileStatuses[index]?.isRetryable !== false ? (
                              <Tooltip title="Erneut versuchen">
                                <IconButton edge="end" onClick={() => retryFile(index)} disabled={processing || isReprocessing}>
                                  <RefreshIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null
                          }
                        >
                          <Box className={styles.statusIconBox}>
                            {getStatusIcon(fileStatuses[index]?.status || 'pending')}
                          </Box>
                          <ListItemText
                            primary={file.name}
                            secondary={getStatusText(file, index)}
                            onClick={() => fileStatuses[index]?.status === 'error' && toggleErrorDetails(index)}
                            sx={{
                              cursor: fileStatuses[index]?.status === 'error' ? 'pointer' : 'default'
                            }}
                          />
                          {expandedErrors[index] && fileStatuses[index]?.status === 'error' && (
                            <Box className={styles.errorDetails}>
                              <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>
                                {fileStatuses[index]?.errorDetail || fileStatuses[index]?.error || 'Unbekannter Fehler'}
                              </Typography>
                              {fileStatuses[index]?.recommendation && (
                                <Typography variant="body2" sx={{ mt: 1, color: getStatusColor('error'), fontWeight: 'bold' }}>
                                  {fileStatuses[index]?.recommendation}
                                </Typography>
                              )}
                              {fileStatuses[index]?.retryCount > 0 && (
                                <Typography variant="caption" sx={{ display: 'block', mt: 1 }}>
                                  Automatische Wiederholungsversuche: {fileStatuses[index]?.retryCount}
                                </Typography>
                              )}
                            </Box>
                          )}
                          {(fileStatuses[index]?.status === 'processing' ||
                            fileStatuses[index]?.status === 'retrying') && (
                            <Box className={styles.progressBarWrapper}>
                              <LinearProgress
                                variant={fileStatuses[index]?.status === 'retrying' ? 'indeterminate' : 'determinate'}
                                value={fileStatuses[index]?.progress || 0}
                                className={`${styles.fileProgressBar} ${fileStatuses[index]?.status === 'retrying' ? styles.retryingProgressBar : ''}`}
                              />
                            </Box>
                          )}
                        </ListItem>
                      ))}
                    </List>
                  </Paper>

                  {/* Show retry all button when there are failures and not currently processing */}
                  {hasRetryableFailures && !processing && !isReprocessing && (
                    <Button
                      onClick={retryAllFailed}
                      disabled={false}
                      isCTA={true}
                      label={`Alle fehlgeschlagenen Dateien wiederholen (${
                        Object.values(fileStatuses).filter(
                          status => status.status === 'error' && status.isRetryable !== false
                        ).length
                      })`}
                    />
                  )}

                  {/* Show simple error alert when processing is complete and there are errors */}
                  {!processing && !isReprocessing && anonymizationStats.errors > 0 && (
                    <Alert
                      severity="error"
                      className={styles.warningAlert}
                      icon={<ErrorIcon sx={{ color: getStatusColor('error') }} />}
                      sx={{ mt: 2 }}
                    >
                      {anonymizationStats.errors === 1 ? (
                        'Eine Datei konnte nicht anonymisiert werden.'
                      ) : (
                        `${anonymizationStats.errors} Dateien konnten nicht anonymisiert werden.`
                      )}
                      {hasRetryableFailures && (
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          Verwenden Sie die Schaltfläche &quot;Erneut versuchen&quot;, um fehlgeschlagene Anonymisierungen zu wiederholen.
                        </Typography>
                      )}
                    </Alert>
                  )}

                  {/* Show error summary when processing is complete and there are errors */}
                  {allProcessed && anonymizationStats.errors > 0 && !processing && !isReprocessing && (
                    <Paper className={styles.errorSummary}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <Typography variant="subtitle1" fontWeight="bold" color={getStatusColor('error')}>
                          Zusammenfassung der Fehler
                        </Typography>
                        <IconButton onClick={() => setShowErrorSummary(!showErrorSummary)}>
                          {showErrorSummary ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                      </Box>

                      <Collapse in={showErrorSummary}>
                        {generateErrorSummary()}
                      </Collapse>
                    </Paper>
                  )}

                  {alreadyAnonymizedFiles.length > 0 && (
                    <Alert
                      severity="warning"
                      className={styles.warningAlert}
                      icon={<WarningIcon sx={{ color: getStatusColor('skipped') }} />}
                    >
                      Die folgenden Dateien sind bereits anonymisiert und werden übersprungen:
                      <Box component="ul" sx={{ m: 0, pl: 2 }}>
                        {alreadyAnonymizedFiles.map((fileName, i) => (
                          <li key={i}>{fileName}</li>
                        ))}
                      </Box>
                    </Alert>
                  )}

                  {/* Show statistics for large file sets */}
                  <LargeFileSetStats />
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseButtonClick}
            disabled={false}
            isCTA={!isClosingBlocked && (allProcessed || files.length === 0 || noFiles)}
            label={allProcessed ? 'Schließen' : 'Abbrechen'}
          />
          <Button
            onClick={handleAnonymizeFiles}
            disabled={files.length === 0 || processing || isReprocessing || allProcessed || noFiles}
            isCTA={files.length > 0 && !processing && !isReprocessing && !allProcessed && !noFiles}
            label={processing || isReprocessing ? 'Läuft...' : 'Anonymisieren'}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        open={showRefreshWarning && (processing || isReprocessing)}
        onClose={() => setShowRefreshWarning(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ color: getStatusColor('error') }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <WarningIcon />
            Achtung: Anonymisierung läuft
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Die Anonymisierung ist noch nicht abgeschlossen. Wenn Sie jetzt die Seite verlassen oder den Dialog schließen, wird der Prozess abgebrochen.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Bitte warten Sie, bis die Anonymisierung abgeschlossen ist.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowRefreshWarning(false)}
            disabled={false}
            isCTA={true}
            label="Verstanden"
          />
        </DialogActions>
      </Dialog>
    </>
  )

  return { handleBatchAnonymizationClick, BatchAnonymizationDialog }
}

export default useBatchAnonymizationLogic
