import React from 'react'
import Hero from '../components/Hero/Hero'
import Partners from '../components/Partners/Partners'
import Clients from '../components/Clients/Clients'
import Products from '../components/Products/Products'
import Highlight from '../components/Highlight/Highlight'
import Dienstleistungen from '../components/Dienstleistungen/Dienstleistungen'
import Team from '../components/Team/Team'
import ContactForm from '../components/ContactForm/ContactForm'
import mediaPlaceholderWhite from '../assets/images/placeholders/white.png'
import mediaPlaceholderYellow from '../assets/images/placeholders/yellow.png'
import illustrationSolutions from '../assets/images/illustrations/solutions.svg'
import illustrationServices from '../assets/images/illustrations/services.svg'
import illustrationQualities from '../assets/images/illustrations/qualities.svg'
import knowledgeToolsLogo from '../assets/images/partners/knowledge-tools.png'
import ndm from '../assets/images/partners/NdM.png'
import { Helmet } from 'react-helmet-async'

const solutionsIntro = {
  media: illustrationSolutions,
	title: 'Eine sichere KI, die Ihre Sprache spricht.',
	text: 'Das macht unser Sprachmodell VerdictLLM aus:',
	facts: [
    'Generative KI auf Basis von Gerichtsurteilen und Rechtsdokumenten',
    'Zugriff über Cloud oder als lokale Installation möglich',
    'Für vielseitige Anwendungen im Rechtswesen einsetzbar',
    'Effizienzgewinne generieren und höherwertigere Aufgaben übernehmen'
	]
}

const solutions = [
  {
    title: 'Urteilsanonymisierung',
    title_mobile: 'Urteilsanonymisierung',
    text: 'VerdictLLM bietet ein Anonymisierungs-Modul für Gerichte und Jurist:innen zur semi-automatisierten Anonymisierung von deutschsprachigen Gerichtsurteilen.',
    text_mobile: 'VerdictLLM bietet ein Anonymisierung-Modul für Gerichte und Jurist:innen zur semi-automatisierten Anonymisierung von deutschsprachigen Gerichtsurteilen.',
    media: mediaPlaceholderWhite,
    facts: [
        'VerdictLLM erkennt relevante Entitäten und bewahrt wichtige Informationen (z.B. Name der Richter:innen)',
        'Kontextbasierte und konfigurierbare Anonymisierung',
        // 'Implementierung als On-Prem Software (lokaler Server) oder SaaS (Cloud)',
        // 'DSGVO-konforme Verschlüsselung von Datenflüssen',
        'Intuitive GUI mit Möglichkeit zur manuellen Nachbearbeitung'
    ]
  },
    {
        title: 'Dokumentenanonymisierung',
        title_mobile: 'Dokumentenanonymisierung',
        text: 'Prompt: “Anonymisiere alle Finanzinformationen im folgenden Vertrag!”\nMit der generativen KI von VerdictLLM können Sie durch Natural Language Processing eine flexible und umfassende Anonymisierung realisieren.',
        text_mobile: 'Mit der generativen KI von VerdictLLM können Sie durch Natural Language Processing eine flexible und umfassende Anonymisierung realisieren.',
        media: mediaPlaceholderWhite,
        facts: [
            'Anonymisierung über textbasierte Prompts',
            'Anwendbar auf Verträge, M&A Dokumente, Finanzdokumente, Patente, Marketing Memos, etc.',
            'Interaktive GUI - Nutzer hat vollständige Kontrolle über Anonymisierung'
            // 'DSGVO-konforme Verschlüsselung von Datenflüssen',
            // 'Interaktive und benutzerfreundliche GUI'
        ]
    },
    {
        title: 'Suchtechnologie',
        title_mobile: 'Suchtechnologie',
        text: 'Mit VerdictLLMs Retrieval-Modul haben wir eine innovative Suchtechnologie entwickelt, die eine effiziente Suche nach relevanten Dokumenten ermöglicht.',
        text_mobile: 'Mit VerdictLLMs Retrieval-Modul haben wir eine innovative Suchtechnologie entwickelt, die eine effiziente Suche nach relevanten Dokumenten ermöglicht.',
        media: mediaPlaceholderWhite,
        facts: [
            'Multiquery-Suche zur optimierten Abfrage von relevanten Dokumenten',
            'Iterative Verfeinerung der Suchergebnisse anhand vortrainierten Knowledge Graphs',
            'Chat-Funktion zur Interaktion mit den resultierenden Dokumenten'
            // 'DSGVO-konforme Verschlüsselung von Datenflüssen',
            // 'Interaktive und benutzerfreundliche GUI'
        ]
    }
]

const servicesIntro = {
  media: illustrationServices,
	title: 'Services, die sich flexibel gestalten.',
	text: 'Wir unterstützen Sie bei Ihren Herausforderungen und entwickeln individuelle, auf Sie zugeschnittene KI-Lösungen.',
	facts: [
    'Fine-tuning von großen Sprachmodellen (LLM) anhand Ihrer Daten',
    'Generierung von synthetischen Daten für Fine-tuning',
    'Lokale oder Cloud Implementierung für maximale Sicherheit',
        // 'Modulare Implementierung von VerdictLLM (Anonymisierung, Suchalgorithmen, Chat)',
        'Modulare Konfigurierung von VerdictLLM'
	]
}

const diensleistungen = [
    {
        title: 'KI-Strategie',
        title_mobile: 'KI-Strategie',
        teaser: 'Vorbereitung Ihrer Unternehmensdaten zur Umsetzung einer KI-Strategie',
        text: 'Elephant Labs unterstützt Ihr Unternehmen dabei, Ihre Daten in ein maschinell lesbares Format zu “übersetzen”, ob Text, Bild, Video oder Audio. Dies ermöglicht die effiziente Suche nach Informationen über semantische Methoden.  \n' +
            'Daten sind häufig ungenutzte Informationen, wir wollen Ihnen neues Leben schenken!'
    },
    {
        title: 'Informationsstrategie',
        title_mobile: 'Informationsstrategie',
        teaser: 'Unternehmensinterne KI-Suchmaschine für eine effiziente Informationsstrategie',
        text: 'Unsere innovative Suchtechnologie lässt sich auch auf Ihr Unternehmen übertragen. Sie bietet eine optimierte Abfrage anhand Multiquery-Suche und erlaubt die iterative Verfeinerung der Ergebnisse anhand eines Knowledge Graphs. \n' +
            'Elephant Labs hilft Ihnen, schneller und präziser Antworten zu finden.'
    },
    {
        title: 'LLMs als Unternehmenslösung',
        title_mobile: 'LLMs als Unternehmenslösung',
        teaser: 'Ein individuelles LLM zum Überwinden Ihrer Herausforderungen',
        text: 'Wir begleiten Sie bei der Entwicklung eines eigenen Sprachmodells, das dank Fine-tuning individuell und kostengünstig an Ihre Anforderungen angepasst ist.   \n' +
            'Nutzen Sie unsere Erfahrung in der wissenschaftlichen Forschung zu künstlicher Intelligenz.'
    }
]
const services = [
    {
        title: 'Knowledge Tools',
        text: 'Das Tool bietet ein modulares Framework, das für eine vielseitige Bereitstellung zur Erfüllung der Kundenanforderungen konzipiert ist. Ursprünglich als webbasiertes Tool zur Schwärzung von Dokumenten konzipiert, kann es nahtlos zu einem Anonymisierungssystem.',
        media: knowledgeToolsLogo,
        description: 'Knowledge Tools',
        facts: [
            'Bedarf an einem flexiblen Tool zur Erfüllung diverser Kundenanforderungen.',
            'Entwicklung eines modularen Frameworks, ursprünglich für die Schwärzung von Dokumenten.',
            'Erweiterung zum nahtlosen Einsatz als Anonymisierungssystem für vielseitige Bereitstellungen.'
        ]
    },
    {
        title: 'Analyse von Hatespeech in sozialen Medien anhand Natural Language Processing Methoden',
        text: 'In einem gemeinsamen Projekt mit dem Journalistennetzwerk Neue Deutsche Medienmacher:innen war Elephant Labs für die Entwicklung und Umsetzung eines Analyseframeworks verantwortlich. Dieses Framework basierte auf der Anwendung neuartiger Natural Language Processing Methoden, um präzise und kontext getreue Auswertungen über Hatespeech in den sozialen Medien anzufertigen.',
        media: ndm,
        description: 'Alternate text for image on page 2',
        facts: [
            'Notwendigkeit, Hatespeech in sozialen Medien präzise zu analysieren.',
            'Partnerschaft mit dem Journalistennetzwerk Neue Deutsche Medienmacher:innen und Entwicklung eines spezialisierten Analyseframeworks.',
            'Einsatz neuartiger Natural Language Processing Methoden für kontextgetreue und genaue Auswertungen von Hatespeech.'
        ]
    }
]

const teamIntro = {
  media: illustrationQualities,
	title: 'Was uns ausmacht.',
	text: 'Elephant Labs ist eine Ausgründung von Wissenschaftlern der Technischen Universität München, Universität St.Gallen und der Universität Zürich mit Sitz im Herzen Bayerns. Gemeinsames Ziel ist die Entwicklung von KI-Lösungen basierend auf state-of-the-art Technologien, um die Herausforderungen in der Rechtspraxis zu bewältigen. Unser Grundsatz ist der verantwortungsvolle Einsatz von künstlicher Intelligenz auf Basis von Explainable AI (XAI). Dabei steht der Mensch im Mittelpunkt.',
	facts: []
}

export default function Home () {
  return (
    <>
        <Helmet>
            <meta charSet="utf-8"/>
            <link rel="canonical" href="https://www.elephantlabs.ai/"/>
            <link rel="icon" href="/static/favicon.png"/>
            <meta name="viewport" content="width=device-width, initial-scale=1"/>
            <meta name="theme-color" content="#141414"/>
            <link rel="apple-touch-icon" href="/static/logo192.png"/>
            <link rel="manifest" href="/static/manifest.json"/>
            <title>Elephant Labs | Dokumenten-Anonymisierung mit KI</title>
            <meta name="description"
                  content="Erschließen Sie das Potenzial von generativer KI: Transformieren Sie ihre sensiblen Dokumente in nutzbare Informationen dank der zuverlässigen und sicheren Anonymisierung von VerdictLLM."/>
            <meta name="keywords"
                  content="LegalTech, LLM, Generative KI, Generative AI, Elephant Labs, VerdictLLM, Anonymisierung, Dokumenten-Anonymisierung"/>
            <meta name="author" content="Elephant Labs GmbH, Morteza Shahrezaye"/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content="Elephant Labs | Dokumenten-Anonymisierung mit KI"/>
            <meta property="og:description"
                  content="Erschließen Sie das Potenzial von generativer KI: Transformieren Sie ihre sensiblen Dokumente in nutzbare Informationen dank der zuverlässigen und sicheren Anonymisierung von VerdictLLM."/>
            <meta property="og:url" content="https://www.elephantlabs.ai"/>
            <meta name="robots" content="index, follow"/>
        </Helmet>
        <Hero/>
        <Partners/>
        <Clients/>
        <Highlight
            item={solutionsIntro}
            hasCTA
        />
        <Dienstleistungen
            headline='Lösungen, die funktionieren.'
            // id='loesungen'
            navigationItems={solutions}
            buttonLabel='Jetzt ausprobieren ↗'
            id='loesungen'
        />
        <Highlight
            item={servicesIntro}
            hasCTA
            reverseColumns
        />
        <Dienstleistungen
            headline='Dienstleistungen die wirken.'
            navigationItems={diensleistungen}
            buttonLabel='Jetzt Kontakt aufnehmen  →'
            id='services'
        />
        <Products
            headline='Projekte'
            id='projekte'
            items={services}
        />
        {/* <Highlight */}
        {/*  id='ueber-uns' */}
        {/*  item={teamIntro} */}
        {/* /> */}
        <Team/>
        <ContactForm/>
    </>
  )
}
