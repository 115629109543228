import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Typography, Button, LinearProgress, CircularProgress, Switch, Tabs, Tab } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setPDFViewerActive } from '../../../slices/collaborativeDocumentAnonymizationStateSlice'
import anonymizePDF from './ServerLogics/anonymizeBackend'
import DownloadRedactedButton from './ServerLogics/DownloadRedactedButton'
import { updateAnonymizationMapping, toggleAnonymization } from '../../../slices/FolderTreeDataStateSlice'
import AnonymizationOptionsDialog from './FileInfoSectionComponents/AnonymizationOptionsDialog'
import RateLimitDialog from './FileInfoSectionComponents/RateLimitDialog'
import AnonymizationErrorDialog from './FileInfoSectionComponents/AnonymizationErrorDialog'
import { useNavigate } from 'react-router-dom'
import SecureFileStatusManager from './Logics/SecureFileStatusManager'
import GroupedEntitiesList from './FileInfoSectionComponents/GroupedEntitiesList'
import AllEntitiesList from './FileInfoSectionComponents/AllEntitiesList'

const buttonStyle = {
  marginTop: '1rem'
}
// Add CSS for smooth color transition
const dynamicProgressStyle = {
  transition: 'background-color 2s ease-in-out',
  backgroundColor: 'primary'
}
function FileInfoSection ({ selectedFileInfo }) {
  const dispatch = useDispatch()
  if (!selectedFileInfo) {
    return (
        <div className="file-info-section"
             style={{ display: 'flex' }}>
          <div className="overlay-info relative">
            <Typography variant="h6" style={{ color: 'hsla(0, 0%, 56%, 1)' }}>Keine Datei ausgewählt</Typography>
            <Typography variant="body1" style={{ color: 'hsla(0, 0%, 56%, 1)', marginTop: '1rem' }}>
              Wählen Sie eine PDF-Datei für die Anonymisierung im Bedienfeld unter dem Root-Ordner. Sie können weitere PDF-Dateien hinzufügen, indem Sie per Rechtsklick auf &quot;Datei hochladen&quot; klicken.
            </Typography>
          </div>
        </div>
    )
  }
  // console.log('selectedFileInfo', selectedFileInfo)
  const navigate = useNavigate()
  const [dynamicClass, setDynamicClass] = useState('dynamic-progress-color')
  const userId = useSelector((state) => state.auth.userId)
  const userLevel = useSelector((state) => state.auth.userLevel)
  const selectedFileData = useSelector(state => state.selectedFileSlice[selectedFileInfo.id])
  const anonymizations = Array.isArray(selectedFileData?.entities) ? selectedFileData.entities : []
  const hasAnonymizationsData = anonymizations.length > 0
  // console.log('selectedFileData', hasAnonymizationsData)
  const [isAnonymizationDialogOpen, setIsAnonymizationDialogOpen] = useState(false)
  const userIdToken = useSelector((state) => state.auth.userIdToken)

  // Add states to manage toggle states
  const [combinedList, setCombinedList] = useState([])
  const [initialOrder, setInitialOrder] = useState([])
  const [activeTab, setActiveTab] = useState(0)

  const [isRateLimitDialogOpen, setRateLimitDialogOpen] = useState(false)
  const [isAnonymizationErrorDialogOpen, setIsAnonymizationErrorDialogOpen] = useState(false)
  const [anonymizationErrorMessage, setAnonymizationErrorMessage] = useState('')
  const [timeToRetry, setTimeToRetry] = useState(0)
  const [numberPages, setNumberPages] = useState(0)

  const [isLoading, setIsLoading] = useState(false)

  // Function to handle closing the dialog
  const handleCloseRateLimitDialog = () => {
    setRateLimitDialogOpen(false)
  }

  const handleCloseAnonymizationErrorDialog = () => {
    setIsAnonymizationErrorDialogOpen(false)
  }

  // Function to handle the "Kontakt aufnehmen" action
  const handleContact = () => {
    // Redirect the user or refinementDialogOpen the contact form
    navigate('/#kontakt')
  }

  const [fileId, setFileId] = useState(selectedFileInfo ? selectedFileInfo.id : null)

  useEffect(() => {
    if (selectedFileInfo && (selectedFileInfo.id !== fileId)) {
      // console.log('resetting states')
      setFileId(selectedFileInfo.id) // Update fileId state
      setInitialOrder([]) // Reset initialOrder
      setCombinedList([]) // Reset combinedList
    }
  }, [selectedFileInfo, fileId, anonymizations])
  const getItemPriority = (item) => {
    if (item.label === 'manual_addition') {
      return 0
    }
    if (item.type === 'image' || item.type === 'filled_shape') {
      return 1
    }
    return 2
  }

  useEffect(() => {
    const uniqueEntities = Array.from(new Set(anonymizations?.map(item => item.text)))
    if (anonymizations && initialOrder.length !== uniqueEntities.length) {
      // Separate text and non-text entities
      const textEntities = []
      const nonTextEntities = []

      uniqueEntities.forEach(entity => {
        const relatedItems = anonymizations.filter(item => item.text === entity)
        const item = relatedItems[0]

        if (item.type === 'text') {
          textEntities.push(item)
        } else {
          nonTextEntities.push(item)
        }
      })
      // Sort text entities by page and then by vertical position (y0)
      const sortedTextEntities = textEntities.sort((a, b) => {
        if (a.page !== b.page) {
          return a.page - b.page
        }
        // If on same page, sort by vertical position (top to bottom)
        return a.bbox.y0 - b.bbox.y0
      })

      // Combine sorted text entities with non-text entities at the end
      const sortedEntities = [...sortedTextEntities, ...nonTextEntities]

      setCombinedList(sortedEntities)
      setInitialOrder(sortedEntities.map(item => item.text))
    }
  }, [anonymizations, initialOrder])

// useEffect to update the local toggle state upon changes in the Redux store
  useEffect(() => {
    // Generate toggle states based on the latest Redux state (after updates are dispatched)
    if (anonymizations) {
      const newToggles = anonymizations.reduce((acc, curr) => {
        acc[curr.text] = curr.annonymize
        return acc
      }, {})
    }
  }, [anonymizations?.entities])

  const handleToggleChange = (entityText) => {
    if (!selectedFileInfo?.id) return

    dispatch(toggleAnonymization({
      fileId: selectedFileInfo.id,
      entityText
    }))
  }

  const openAnonymizationDialog = () => setIsAnonymizationDialogOpen(true)
  const closeAnonymizationDialog = () => setIsAnonymizationDialogOpen(false)
  const handleAnonymizationSelection = async (anonymizeImages) => {
    // setIsLoading(true)
    let errorReceived = {}
    errorReceived.message = ''

    try {
      await SecureFileStatusManager.setFileBusy(userId, selectedFileInfo.id)

      const newAnon = await anonymizePDF({
        fileId: selectedFileInfo.id,
        bearerToken: userIdToken,
        redactImages: anonymizeImages
      })

      console.log('newAnon', newAnon)
      dispatch(updateAnonymizationMapping({
        fileId: selectedFileInfo.id,
        newAnonymization: newAnon
      }))
    } catch (error) {
      errorReceived = error
      // Simply show any error from API in the dialog
      setAnonymizationErrorMessage(error.message)
      console.error('Anonymization Error:', error)
      setIsAnonymizationErrorDialogOpen(true)
    } finally {
      // Ensure clearFileBusy is not called again if FileBusyError was caught
      if (errorReceived.message !== 'FileBusyError') {
        await SecureFileStatusManager.clearFileBusy(userId, selectedFileInfo.id)
      }
      // setIsLoading(false)
      closeAnonymizationDialog()
    }
  }

  const handlePreviewFileClick = () => {
    dispatch(setPDFViewerActive(true))
  }

  const getFileStatus = () => {
    if (selectedFileData.busySince || selectedFileData.status === 'PROCESSING') {
      return 'Wird anonymisiert'
    }
    if (selectedFileData.anonymized_entities) {
      return 'Vollständig anonymisiert'
    }
    return 'Nicht anonymisiert'
  }

  const isAnonymizeDisabled = Boolean(
      selectedFileData.anonymized_entities ||
      selectedFileData.busySince ||
      selectedFileData.status === 'PROCESSING'
  )

  return (
      <div className="file-info-section">
        {isLoading && (
            <div className="login-overlay">
              <CircularProgress/>
            </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ flex: 1, textAlign: 'left' }}>
            <Typography variant="h5" className="file-name-header"
                        style={{ fontWeight: 'bold' }}>{selectedFileInfo.text}</Typography>
          </div>
          <Button
              className="preview-btn action"
              style={{ marginLeft: 'auto' }}
              variant="outlined"
              onClick={handlePreviewFileClick}
          >
            {/* <ZoomInIcon/> /!* Magnifier Icon *!/ */}
            Vorschau
          </Button>
        </div>
        <div className="file-status">
          <p><span style={{ fontWeight: 'bold' }}>Status:</span> {getFileStatus()}</p>
          {hasAnonymizationsData && (
              <>
                {getFileStatus() === 'Anonymisiert mit Prompt' && (
                    <p><span style={{ fontWeight: 'bold' }}>Prompt:</span></p>
                )}
                <DownloadRedactedButton
                    fileId={selectedFileData.id}
                    userId={userId}
                    userIdToken={userIdToken}
                    ners={selectedFileData.entities}
                    setIsLoading={setIsLoading}
                    setAnonymizationErrorMessage={setAnonymizationErrorMessage}
                    hasAnonymizationsData={hasAnonymizationsData}
                />
                </>
          )}
          <div className="file-stage-visualization">
            {(selectedFileData.busySince || selectedFileData.status === 'PROCESSING') &&
                <LinearProgress
                    variant="determinate"
                    value={0}
                    className={dynamicClass}
                    style={dynamicProgressStyle}
                />
            }
          </div>
        </div>
        <div className="button-container" style={{ textAlign: 'center', display: 'flex', justifyContent: 'left' }}>
          {isAnonymizeDisabled && getFileStatus() === 'Anonymisiert mit Prompt' ? (
              <div>
                <Typography variant="h5" style={{ textAlign: 'left', marginTop: '2.5rem', marginBottom: '0.5rem', fontWeight: 'bold' }}>Anonymisierung verfeinern</Typography>
              </div>
          ) : (
              <Button
                  variant="outlined"
                  className={`preview-btn ${!isAnonymizeDisabled ? 'action' : ''}`}
                  style={buttonStyle}
                  onClick={openAnonymizationDialog}
                  disabled={isAnonymizeDisabled}
              >
                Anonymisieren
              </Button>
          )}
          <AnonymizationOptionsDialog
              open={isAnonymizationDialogOpen}
              onAnonymize={handleAnonymizationSelection}
              onClose={closeAnonymizationDialog}
          />
          <RateLimitDialog
              open={isRateLimitDialogOpen}
              onClose={handleCloseRateLimitDialog}
              onContact={handleContact}
              timeToRetry={timeToRetry}
              numberPages={numberPages}
          />
          <AnonymizationErrorDialog
              open={isAnonymizationErrorDialogOpen}
              onClose={handleCloseAnonymizationErrorDialog}
              onContact={handleContact}
              errorMessage={anonymizationErrorMessage}
          />
        </div>

        {hasAnonymizationsData && (
            <>
              <Typography
                  variant="h5"
                  style={{
                    textAlign: 'left',
                    marginTop: '2.5rem',
                    marginBottom: '0.5rem',
                    fontWeight: 'bold'
                  }}
              >
                Liste an Entitäten
              </Typography>

              <Tabs
                  value={activeTab}
                  onChange={(e, newValue) => setActiveTab(newValue)}
                  style={{ marginBottom: '1rem' }}
              >
                <Tab label="Alle Entitäten" />
                <Tab label="Nach Kategorie" />
              </Tabs>

              {activeTab === 0 ? (
                  <AllEntitiesList
                      combinedList={combinedList}
                      anonymizations={anonymizations}
                      onToggleChange={handleToggleChange}
                  />
              ) : (
                  <GroupedEntitiesList
                      anonymizations={anonymizations}
                      onToggleChange={handleToggleChange}
                  />
              )}
            </>
        )}

      </div>
  )
}

FileInfoSection.propTypes = {
  selectedFileInfo: PropTypes.any,
  updateSelectedFileInfo: PropTypes.func.isRequired,
  refreshFolderTreeData: PropTypes.func.isRequired
}
export default FileInfoSection
