import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { toggleAnonymization } from '../../../../slices/FolderTreeDataStateSlice'
import { handleMouseOver } from './annotationComponents/handleMouseOver'
import { handleMouseOut } from './annotationComponents/handleMouseOut'

function Annotations ({ fileId, currentPage, pdfViewerRef, anonymizations, viewMode }) {
    const dispatch = useDispatch()
    const [annotations, setAnnotations] = useState([])
    const [showPopup, setShowPopup] = useState(false)
    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 })
    const [currentAnnotation, setCurrentAnnotation] = useState({})
    const containerRef = useRef(null)

    useEffect(() => {
        if (!anonymizations) return
        const pageAnnotations = anonymizations
            .filter(annotation => annotation.page === currentPage - 1)
            .map(annotation => ({
                ...annotation.bbox,
                id: annotation.type === 'text' ? annotation.text : `${annotation.type}_${annotation.page}_${annotation.bbox.x0}_${annotation.bbox.y0}`,
                name: annotation.type === 'text' ? annotation.text : annotation.label || annotation.type,
                type: annotation.type,
                label: annotation.label,
                text: annotation.text,
                annonymize: annotation.annonymize || false // Directly use the anonymize value from Redux
            }))

        setAnnotations(pageAnnotations)
    }, [currentPage, anonymizations])

    // Add a document-level mouse tracking as a fallback
    useEffect(() => {
        const handleDocumentMouseMove = (e) => {
            if (!showPopup) return

            // Get the coordinates relative to the PDF viewer
            const containerRect = pdfViewerRef.current.getBoundingClientRect()
            const mouseX = e.clientX - containerRect.left
            const mouseY = e.clientY - containerRect.top

            // Check if mouse is over any annotation
            const mouseIsOverAnnotation = annotations.some(annotation => {
                return (
                    mouseX >= annotation.x0 &&
                    mouseX <= annotation.x1 &&
                    mouseY >= annotation.y0 &&
                    mouseY <= annotation.y1
                )
            })

            // console.log('🔍 Document mousemove - over annotation?', mouseIsOverAnnotation)

            if (!mouseIsOverAnnotation) {
                // console.log('🔍 Document mousemove - hiding popup')
                setShowPopup(false)
            }
        }

        document.addEventListener('mousemove', handleDocumentMouseMove)
        return () => {
            document.removeEventListener('mousemove', handleDocumentMouseMove)
        }
    }, [annotations, showPopup, pdfViewerRef])

    // Add ESC key listener to dismiss popup
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape' && showPopup) {
                // console.log('🔍 ESC key - hiding popup')
                setShowPopup(false)
            }
        }

        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [showPopup])

    // Remove the existing container mouse tracking as it might be conflicting
    // and replace with a simpler approach:
    useEffect(() => {
        if (!containerRef.current) return

        const handleContainerMouseLeave = () => {
            // console.log('🔍 Container mouseleave - hiding popup')
            setShowPopup(false)
        }

        containerRef.current.addEventListener('mouseleave', handleContainerMouseLeave)
        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener('mouseleave', handleContainerMouseLeave)
            }
        }
    }, [])

    const handleAnnotationClick = (annotation) => {
        if (!annotation.text) return
        // console.log('annotation', annotation.text)

        // Use the fileId from props directly
        const currentFileId = fileId
        // console.log('fileId', currentFileId)

        if (!currentFileId) {
            // console.warn('fileId is undefined in handleAnnotationClick', { props: { fileId, currentPage } })
            return // Prevent dispatch if fileId is undefined
        }

        dispatch(toggleAnonymization({
            fileId: currentFileId,
            entityText: annotation.text
        }))
    }

    return (
        <div
            ref={containerRef}
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 100, pointerEvents: 'none' }}
        >
            {showPopup && (
                <div
                    style={{
                        position: 'absolute',
                        left: `${popupPosition.x}px`,
                        top: `${popupPosition.y}px`,
                        pointerEvents: 'none'
                    }}
                    className="popup-text"
                >
                    <div>
                        {currentAnnotation.type === 'text' ? (
                            <>
                                Kategorie<br/><b>{currentAnnotation.label}</b>
                            </>
                        ) : (
                            <>
                                Typ<br/><b>{currentAnnotation.label || currentAnnotation.type}</b>
                            </>
                        )}
                    </div>
                </div>
            )}

            <svg className="annotations-overlay">
                {annotations.map((annotation, index) => (
                    <rect
                        key={`${annotation.id}-${index}`}
                        x={annotation.x0}
                        y={annotation.y0}
                        width={annotation.x1 - annotation.x0}
                        height={annotation.y1 - annotation.y0}
                        fill={viewMode === 'blackout' && annotation.annonymize ? 'black' : 'transparent'}
                        stroke={annotation.annonymize ? 'black' : 'gray'}
                        strokeWidth={annotation.annonymize ? 0.9 : 1}
                        strokeDasharray={annotation.annonymize ? '0' : '8,3'}
                        className="annotation"
                        style={{ pointerEvents: 'auto' }}
                        onClick={() => handleAnnotationClick(annotation)}
                        onMouseOver={(e) => handleMouseOver(annotation, setPopupPosition, setShowPopup, setCurrentAnnotation, pdfViewerRef)(e)}
                        onMouseOut={() => handleMouseOut(setShowPopup)}
                    />
                ))}
            </svg>
        </div>
    )
}

Annotations.propTypes = {
    fileId: PropTypes.string.isRequired,
    currentPage: PropTypes.number.isRequired,
    pdfViewerRef: PropTypes.object.isRequired,
    anonymizations: PropTypes.arrayOf(PropTypes.shape({
        bbox: PropTypes.object.isRequired,
        page: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
        text: PropTypes.string,
        label: PropTypes.string,
        annonymize: PropTypes.bool
    })),
    viewMode: PropTypes.string.isRequired
}

export default Annotations
