import React from 'react'
import { Typography, Switch } from '@mui/material'
import PropTypes from 'prop-types'

const AllEntitiesList = ({ combinedList, anonymizations, onToggleChange }) => {
    return (
        <div className="toggle-grid">
            <div className="toggle-row header">
                <Typography
                    variant="subtitle1"
                    className="toggle-item"
                    style={{ fontWeight: 'bold', textAlign: 'center' }}
                >
                </Typography>
                <Typography
                    variant="subtitle1"
                    className="toggle-item"
                    style={{ fontWeight: 'bold', textAlign: 'left' }}
                >
                    Entität
                </Typography>
                <Typography
                    variant="subtitle1"
                    className="toggle-item"
                    style={{ fontWeight: 'bold', textAlign: 'left' }}
                >
                    Kategorie
                </Typography>
            </div>
            {combinedList.map((item, index) => (
                <div key={index} className="toggle-row">
                    <div className={item.Application === 'Not Relevant' ? 'toggle-item toggle-item-orange' : item.type === 'image' || item.type === 'filled_shape' ? 'toggle-item toggle-item-red' : 'toggle-item'}>
                        <Switch
                            checked={anonymizations.find(a => a.text === item.text)?.annonymize || false}
                            onChange={() => onToggleChange(item.text)}
                            size="small"
                        />
                    </div>
                    <Typography variant="body1" className="toggle-item">{item.text}</Typography>
                    <Typography variant="body1" className="toggle-item">{item.label}</Typography>
                </div>
            ))}
        </div>
    )
}

AllEntitiesList.propTypes = {
    combinedList: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        type: PropTypes.string,
        Application: PropTypes.string,
        annonymize: PropTypes.bool
    })).isRequired,
    anonymizations: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string.isRequired,
        annonymize: PropTypes.bool
    })).isRequired,
    onToggleChange: PropTypes.func.isRequired
}

export default AllEntitiesList
