import React from 'react'
import { Container, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'

const PrivacyPolicy = () => {
    const headerHeight = useSelector((state) => state.layout.headerHeight)
    return (
        <Container style={{ paddingTop: headerHeight }}>
            <Helmet>
                <title>Privacy Policy | Elephant Labs</title>
                <link rel="canonical" href="https://www.elephantlabs.ai/privacy" />
            </Helmet>
            <Typography variant="h4" gutterBottom>Datenschutzerklärung</Typography>

            {/* General Information */}
            <Typography variant="h5" gutterBottom>Datenschutz auf einen Blick</Typography>

            <Typography variant="h6">Allgemeine Hinweise</Typography>
            <Typography paragraph>
                Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
            </Typography>

            {/* Data Collection on the Website */}
            <Typography variant="h6">Datenerfassung auf dieser Website</Typography>

            {/* Data Collection Responsibility */}
            <Typography variant="subtitle1" gutterBottom>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</Typography>
            <Typography paragraph>
                Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt „Datenschutzbeauftragter“ in dieser Datenschutzerklärung entnehmen.
            </Typography>

            {/* How Do We Collect Your Data? */}
            <Typography variant="subtitle1" gutterBottom>Wie erfassen wir Ihre Daten?</Typography>
            <Typography paragraph>
                Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein Kontaktformular eingeben. Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website betreten.
            </Typography>

            {/* Use of Firebase and Firestore */}
            <Typography variant="subtitle1" gutterBottom>Nutzung von Firebase und Firestore</Typography>
            <Typography paragraph>
                Für die Speicherung von Dateien, die Nutzerinnen und Nutzer auf unseren Service hochladen, nutzen wir Google Firebase und Google Firestore. Diese Dienste dienen der Verwaltung der Benutzerauthentifizierung und der Dateispeicherung. Die Dateien werden ausschließlich für die von der Nutzerin oder vom Nutzer angeforderte Analyse verwendet. Elephant Labs erhält keinen Zugriff auf den Inhalt der Dateien und verwendet die Daten nicht für Trainingszwecke, zur Nutzeranalyse oder zur Verbesserung unserer Modelle. Nutzer haben jederzeit die Möglichkeit, ihre Dateien zu löschen, woraufhin diese vollständig aus Firestore entfernt werden. Das schließt alle Spuren oder Referenzen auf die gelöschten Dateien in unseren Systemen ein.
            </Typography>

            {/* What Do We Use Your Data For? */}
            <Typography variant="subtitle1" gutterBottom>Wofür nutzen wir Ihre Daten?</Typography>
            <Typography paragraph>
                Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden. Das schließt nicht die über Firebase und Firestore gespeicherten Dateien ein. Die Speicherung und Verarbeitung von Dateien, die Nutzerinnen und Nutzer in unseren Services bereitstellen, erfolgt ausschließlich zur Durchführung der von der Nutzerin oder vom Nutzer angeforderten Funktionen innerhalb dieser Anwendungen. Elephant Labs analysiert oder verwendet diese Dateien nicht, um unsere Produkte oder Dienstleistungen zu verbessern. Nutzerdaten, einschließlich hochgeladener Dateien, stehen dem Nutzer zur vollständigen Kontrolle zur Verfügung. Nutzer haben jederzeit die Möglichkeit, ihre Dateien zu löschen, woraufhin diese vollständig aus Firestore entfernt werden.
            </Typography>

            {/* Hosting and Content Delivery Networks */}
            <Typography variant="h5" gutterBottom>Hosting und Content Delivery Networks (CDN)</Typography>

            {/* Google Firebase & Firestore */}
            <Typography variant="h6">Google Firebase & Firestore</Typography>
            <Typography paragraph>
                Diese Website verwendet Google Firebase und Firestore als Plattformdienste von Google für das Hosting, die Verwaltung der Benutzerauthentifizierung sowie für die Speicherung von Dateien im Rahmen des Playgrounds, wenn die Nutzerin oder der Nutzer Dateien hochlädt. Google Firebase und Firestore sind Dienste der Google Ireland Limited (`&quot;Google&quot;`), Gordon House, Barrow Street, Dublin 4, Irland.
            </Typography>
            <Typography paragraph>
                Elephant Labs wird den Inhalt der Dateien in keinem Fall einsehen. Löschen Nutzerinnen oder Nutzer ihre Daten aus den von Elephant Labs angebotenen Services auf dieser Seite, sind die Daten unwiderruflich vollständig und ohne Verzögerung gelöscht. Das umfasst auch sämtliche Spuren oder Referenzen auf die gelöschten Dateien in unseren Systemen. Das stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
            </Typography>

            {/* Google App Engine Hosting */}
            <Typography variant="h6">Hosting durch Google App Engine</Typography>
            <Typography paragraph>
                Diese Website wird auf der Infrastruktur von Google App Engine gehostet. Dabei können personenbezogene Daten auf Servern in verschiedenen Ländern weltweit verarbeitet und gespeichert werden. Google verpflichtet sich, hohe Datenschutz- und Sicherheitsstandards einzuhalten. Weitere Informationen finden Sie in der Datenschutzerklärung von Google unter https://www.google.com/policies/privacy/
            </Typography>

            {/* Google Forms for Contact Forms */}
            <Typography variant="h6">Nutzung von Google Forms</Typography>
            <Typography paragraph>
                Für unser Kontaktformular verwenden wir Google Forms, einen Dienst von Google. Die von Ihnen in Google Forms eingegebenen Daten werden gemäß der Datenschutzbestimmungen von Google verarbeitet. Wir empfehlen Ihnen, sich mit den Datenschutzpraktiken von Google vertraut zu machen.
            </Typography>
            <Typography paragraph>
                Weitere Informationen finden Sie in der Datenschutzerklärung von Google: https://www.google.com/policies/privacy/.
            </Typography>

            {/* More sections based on the structure you provided */}
            <Typography variant="h5">Weitere Informationen</Typography>
            <Typography variant="h6">Datenschutzbeauftragter</Typography>
            {/* <Typography paragraph> */}
            {/*    Für Fragen zum Datenschutz steht Ihnen unser Datenschutzbeauftragter gerne zur Verfügung:<br/> */}
            {/*    Tilman Tunk<br/> */}
            {/*    E-Mail: t.tunk@elephantlabs.ai */}
            {/* </Typography> */}

            <Typography variant="h6">Änderungen dieser Datenschutzerklärung</Typography>
            <Typography paragraph>
                Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z. B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.
            </Typography>

            <Typography variant="h6">Beschwerderecht bei der zuständigen Aufsichtsbehörde</Typography>
            <Typography paragraph>
                Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet andersweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
            </Typography>

            <Typography variant="h6">Welche Rechte haben Sie bezüglich Ihrer Daten?</Typography>
            <Typography paragraph>
                Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem das Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
            </Typography>

            <Typography variant="h6">Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)</Typography>
            <Typography paragraph>
                Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, haben sie jederzeit das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, gegen die Verarbeitung ihrer personenbezogenen Daten Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Die jeweilige Rechtsgrundlage, auf der eine Verarbeitung beruht, entnehmen Sie dieser Datenschutzerklärung. Wenn Sie Widerspruch einlegen, werden wir ihre betroffenen personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die ihre Interessen, Rechte und Freiheiten überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 1 DSGVO).
            </Typography>
            <Typography paragraph>
                Werden ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, so haben sie das Recht, jederzeit Widerspruch gegen die Verarbeitung sie betreffender personenbezogener Daten zum zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Wenn sie widersprechen, werden ihre personenbezogenen Daten anschließend nicht mehr zum Zwecke der Direktwerbung verwendet (Widerspruch nach Art. 21 Abs. 2 DSGVO).
            </Typography>
            <Typography paragraph>
                Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.
            </Typography>
        </Container>
    )
}

export default PrivacyPolicy
