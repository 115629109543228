import { useDispatch, useSelector } from 'react-redux'
import { setFileData } from '../../../../slices/FolderTreeDataStateSlice'
import { secureDownloadFile } from '../PDFViewer/secureDownload'
import { downloadAnonymizations } from '../PDFViewer/downloadAnonymizations'
import { v4 as uuidv4 } from 'uuid'

const useHandleItemClick = (setSelectedFileInfo, setIsLoading, userId, setCurrentPage) => {
  const dispatch = useDispatch()
  const fileData = useSelector(state => state.selectedFileSlice)
  const PDF_EXTENSION = '.pdf'

  return async (itemData) => {
    console.log('itemData', itemData)
    setIsLoading(true)
    setCurrentPage(1)
    if (itemData?.type === 'file' && itemData?.text?.toLowerCase().endsWith(PDF_EXTENSION)) {
      const fileDispatched = fileData[itemData.id]
      if (!fileDispatched) {
        const url = await secureDownloadFile(userId, itemData.id)
        let anonymizations = [] // Initialize as empty array by default
        if (itemData.anonymized_entities) {
          anonymizations = await downloadAnonymizations(userId, itemData.id)
          // for each item in anonymizations, add a unique uuid identifier
          anonymizations = anonymizations.map((entity) => ({
            ...entity,
            id: uuidv4()
          }))
        }
        // Prepare the comprehensive dataset
        const fileInfo = {
          ...itemData,
          url,
          entities: anonymizations // This will always be an array now
        }
        dispatch(setFileData(fileInfo))
        setSelectedFileInfo(fileInfo)
      } else {
        setSelectedFileInfo(fileDispatched)
      }
    } else {
      setSelectedFileInfo(null)
    }
    setIsLoading(false)
  }
}

export default useHandleItemClick
