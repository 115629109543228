export const handleMouseOut = (setShowPopup) => (event) => {
  console.log('🔍 MouseOut triggered')

  // Force hide popup after a short delay
  setTimeout(() => {
    // console.log('🔍 Forcing popup hide from timeout')
    setShowPopup(false)
  }, 100)

  // Immediate hide for normal cases
  const relatedTarget = event.relatedTarget
  // console.log('🔍 Related target:', relatedTarget)

  // Check if we're hovering over another annotation
  const isOverAnnotation = relatedTarget &&
    (relatedTarget.tagName?.toLowerCase() === 'rect' ||
     relatedTarget.closest?.('rect.annotation'))

  // console.log('🔍 Is over annotation:', isOverAnnotation)

  if (!isOverAnnotation) {
    // console.log('🔍 Hiding popup immediately')
    setShowPopup(false)
  }
}
