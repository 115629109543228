import React from 'react'
import { Container, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'

const Imprint = () => {
    const headerHeight = useSelector((state) => state.layout.headerHeight)
    return (
      <Container style={{ marginTop: headerHeight }}>
          <Helmet>
              <title>Impressum | Elephant Labs</title>
              <link rel="canonical" href="https://www.elephantlabs.ai/impressum" />
          </Helmet>
        <Typography variant="h3" gutterBottom>Impressum</Typography>

        <Typography variant="h6" gutterBottom>Elephant Labs GmbH</Typography>
        <Typography paragraph>Ismaninger Straße 136<br />
          81675 München</Typography>
        <Typography paragraph>Handelsregister: HRB 281624<br />
          Registergericht: Amtsgericht München</Typography>
        <Typography paragraph>Vertreten durch:<br />
          Dr. Morteza Shahrezaye</Typography>

        <Typography variant="h6" gutterBottom>Kontakt</Typography>
        <Typography paragraph>Telefon: +4917661949459<br />
          E-Mail: kontakt@elephantlabs.ai</Typography>

        <Typography variant="h6" gutterBottom>Umsatzsteuer-ID</Typography>
        <Typography paragraph>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:<br />
          DE359394888</Typography>

        <Typography variant="h6" gutterBottom>Verbraucherstreitbeilegung/Universalschlichtungsstelle</Typography>
        <Typography paragraph>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</Typography>
      </Container>
  )
}

export default Imprint
