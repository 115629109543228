import React, { useRef, useState, useEffect } from 'react'
import { Document, Page } from 'react-pdf'
import { Typography, Button, IconButton, Switch, Menu, MenuItem, CircularProgress } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import Annotations from './Annotations'
import { useDispatch, useSelector } from 'react-redux'
import { setPDFViewerActive } from '../../../../slices/collaborativeDocumentAnonymizationStateSlice'
import { updateAnonymizationMapping } from '../../../../slices/FolderTreeDataStateSlice'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import PDFViewerContextMenu from './PDFViewerContextMenu/PDFViewerContextMenu'

function PDFViewer ({ fileID, currentPage, setCurrentPage, numPages, setNumPages }) {
    const [viewMode, setViewMode] = useState('box')
    const [annotationStatus, setAnnotationStatus] = useState({})
    const [scale, setScale] = useState(1)
    const [contextMenuPosition, setContextMenuPosition] = useState(null)
    const [selectedText, setSelectedText] = useState('')
    const [extendedSelectedText, setExtendedSelectedText] = useState('')
    const dispatch = useDispatch()
    const pdfViewerRef = useRef(null)
    const userIdToken = useSelector((state) => state.auth.userIdToken)
    const userId = useSelector((state) => state.auth.userId)
    const selectedFileData = useSelector(state => state.selectedFileSlice[fileID])
    const [isLoading, setIsLoading] = useState(false) // Loading state
    // console.log('fileIsssD', fileID)
    const handleContextMenu = (event) => {
        event.preventDefault()
        const selection = window.getSelection()
        const selectedText = selection.toString().trim()

        if (selectedText) {
            const containerRect = pdfViewerRef.current.getBoundingClientRect()

            let extendedSelectedText = selectedText
            const range = selection.getRangeAt(0)
            const startOffset = range.startOffset
            const endOffset = range.endOffset
            const startContainer = range.startContainer
            const endContainer = range.endContainer

            if (startContainer === endContainer && startContainer.nodeType === Node.TEXT_NODE) {
                const textContent = startContainer.textContent
                let beforeSelection = textContent[startOffset - 1] || ''
                let afterSelection = textContent[endOffset] || ''
                if (beforeSelection.length === 0) {
                    beforeSelection = ' '
                }
                if (afterSelection.length === 0) {
                    afterSelection = ' '
                }
                // console.log(beforeSelection, afterSelection)
                extendedSelectedText = beforeSelection + selectedText + afterSelection
            }

            setSelectedText(selectedText)
            setExtendedSelectedText(extendedSelectedText)
            setContextMenuPosition({
                mouseX: event.clientX,
                mouseY: event.clientY
            })
        }
    }

    const handleCloseContextMenu = () => {
        setContextMenuPosition(null)
    }

    const calculateScale = (aspectRatio) => {
        const navigationHeight = document.querySelector('.page-navigation').offsetHeight
        const availableHeight = window.innerHeight - navigationHeight
        const pageWidth = availableHeight * aspectRatio
        const containerWidth = pdfViewerRef.current.offsetWidth
        const newScale = containerWidth / pageWidth
        setScale(newScale)
    }

    const onPageLoadSuccess = (page) => {
        const { width, height } = page.getViewport({ scale: 1 })
        const aspectRatio = width / height
        calculateScale(aspectRatio)
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages)
    }

    const handleViewModeChange = (event) => {
        setViewMode(event.target.checked ? 'blackout' : 'box')
    }

    const goToPreviousPage = () => {
        if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1)
    }

    const goToNextPage = () => {
        if (currentPage < numPages) setCurrentPage((prevPage) => prevPage + 1)
    }

    const onClose = () => {
        dispatch(setPDFViewerActive(false))
    }

    const anonymizations = useSelector((state) => state.selectedFileSlice[fileID]?.entities)
    const blobUrl = useSelector((state) => state.selectedFileSlice[fileID]?.url)
    // console.log('asd', anonymizations)

    useEffect(() => {
        const initialStatus = anonymizations?.reduce((status, annotation) => {
            status[annotation.text] = annotation
            return status
        }, {}) || {}
        setAnnotationStatus(initialStatus)
    }, [anonymizations])

    const previousAnnotationStatusRef = useRef()

    useEffect(() => {
        const previousAnnotationStatus = previousAnnotationStatusRef.current
        const hasChanges = Object.entries(annotationStatus).some(([entity, status]) => {
            return (!previousAnnotationStatus || previousAnnotationStatus[entity]?.annonymize !== status.annonymize)
        })

        if (hasChanges && anonymizations) {
            const updatedAnonymizations = anonymizations.map(annotation => {
                const status = annotationStatus[annotation.text]
                return status ? { ...annotation, annonymize: status.annonymize } : annotation
            })

            dispatch(updateAnonymizationMapping({ fileId: fileID, newAnonymization: updatedAnonymizations }))
        }

        previousAnnotationStatusRef.current = annotationStatus
    }, [anonymizations, annotationStatus, dispatch, fileID])

    useEffect(() => {
        const calculateAvailableHeight = () => {
            const navigationHeight = document.querySelector('.page-navigation').offsetHeight
            const availableHeight = window.innerHeight - navigationHeight
        }

        window.addEventListener('resize', calculateAvailableHeight)
        calculateAvailableHeight()

        return () => {
            window.removeEventListener('resize', calculateAvailableHeight)
        }
    }, [])

    return (
        <div>
            <div className="page-navigation">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton style={{ marginRight: 'auto', margin: '0 5px', padding: 0 }} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="subtitle1" style={{ marginRight: 0 }}>
                        Rahmen
                    </Typography>
                    <div className="toggle-item-orange">
                        <Switch
                            checked={viewMode === 'blackout'}
                            onChange={handleViewModeChange}
                            name="viewModeSwitch"
                        />
                    </div>
                    <Typography variant="subtitle1" style={{ marginLeft: 0, marginRight: 20 }}>
                        Schwärzung
                    </Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                        variant="outlined"
                        className={`nav-btn ${currentPage === 1 ? 'disabled' : 'action'}`}
                        onClick={goToPreviousPage}
                        disabled={currentPage === 1}
                        style={{ borderRadius: '8px' }}
                    >
                        <ArrowBackIcon />
                    </Button>
                    <Typography variant="subtitle1" style={{ margin: '0 15px' }}>
                        Seite {currentPage} von {numPages}
                    </Typography>
                    <Button
                        variant="outlined"
                        className={`nav-btn ${currentPage === numPages ? 'disabled' : 'action'}`}
                        onClick={goToNextPage}
                        disabled={currentPage === numPages}
                        style={{ borderRadius: '8px' }}
                    >
                        <ArrowForwardIcon />
                    </Button>
                </div>
            </div>
            <div ref={pdfViewerRef} onContextMenu={handleContextMenu}>
                <Document file={blobUrl} onLoadSuccess={onDocumentLoadSuccess}>
                    <div style={{ position: 'relative' }}>
                        <Page pageNumber={currentPage} onLoadSuccess={onPageLoadSuccess} />
                        <Annotations
                            fileId={fileID}
                            currentPage={currentPage}
                            pdfViewerRef={pdfViewerRef}
                            anonymizations={anonymizations}
                            annotationStatus={annotationStatus}
                            viewMode={viewMode}
                        />
                    </div>
                </Document>
            </div>
            <PDFViewerContextMenu
                contextMenuPosition={contextMenuPosition}
                handleClose={handleCloseContextMenu}
                selectedText={selectedText}
                extendedSelectedText={extendedSelectedText}
                fileId={fileID}
                userIdToken={userIdToken}
                userId={userId}
                selectedFileData={selectedFileData}
                setIsLoading={setIsLoading}
            />
            {isLoading && (
                <div className="loading-overlay">
                    <CircularProgress />
                </div>
            )}
        </div>
    )
}

PDFViewer.propTypes = {
    fileID: PropTypes.string.isRequired,
    currentPage: PropTypes.number.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    numPages: PropTypes.number,
    setNumPages: PropTypes.func.isRequired
}

export default PDFViewer
