import { makeStyles } from '@mui/styles'
import '../../../../../../styles/BatchOperations.scss'
import variables from '../../../../../../styles/_variables.scss'

// Extract color variables from SCSS variables
const colors = {
  primary: variables.black || '#222222',
  error: variables.orange || '#FF5722',
  warning: variables.orange_half_pale || '#FF5722',
  success: variables.green || '#83c41a',
  border: variables.lightgray || '#EBEBEB',
  text: variables.black || '#222222',
  textSecondary: variables.darkgray || '#909090'
}

// Common styles for all batch operation components
export const useBatchOperationStyles = makeStyles({
  dialogTitle: {
    '& .MuiTypography-subtitle2': {
      marginTop: '4px',
      color: colors.textSecondary
    }
  },
  successMessage: {
    color: colors.success,
    marginTop: '4px'
  },
  overallProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    '& .MuiLinearProgress-bar': {
      backgroundColor: colors.primary
    }
  },
  progressContainer: {
    marginTop: '16px',
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  statusBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  fileStatusPaper: {
    maxHeight: '300px',
    overflow: 'auto',
    marginTop: '12px',
    padding: '4px 8px',
    borderRadius: '8px',
    border: `1px solid ${colors.border}`
  },
  statusIconBox: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
    minWidth: '24px',
    justifyContent: 'center'
  },
  itemProgress: {
    '& .MuiLinearProgress-bar': {
      backgroundColor: colors.primary
    }
  },
  fileProgressBar: {
    height: '3px',
    borderRadius: '1.5px',
    '& .MuiLinearProgress-bar': {
      backgroundColor: colors.error
    }
  },
  retryingProgressBar: {
    '& .MuiLinearProgress-bar': {
      backgroundColor: colors.warning
    }
  },
  progressBarWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    paddingLeft: '6px',
    paddingRight: '6px',
    boxSizing: 'border-box',
    paddingBottom: '2px',
    zIndex: 1,
    pointerEvents: 'none'
  },
  fileInput: {
    marginTop: '16px',
    marginBottom: '16px',
    padding: '10px',
    border: `1px dashed ${colors.border}`,
    borderRadius: '4px',
    width: '100%'
  },
  warningAlert: {
    marginTop: '16px',
    backgroundColor: `rgba(${colors.warning}, 0.05)`,
    color: colors.warning
  },
  errorDetails: {
    marginTop: '6px',
    padding: '6px',
    backgroundColor: `rgba(${colors.error}, 0.05)`,
    borderRadius: '4px',
    fontSize: '0.875rem',
    color: colors.error
  },
  errorSummary: {
    marginTop: '16px',
    padding: '12px',
    borderRadius: '8px',
    border: `1px solid ${colors.error}`,
    backgroundColor: `rgba(${colors.error}, 0.05)`
  },
  retryAllButton: {
    marginTop: '16px',
    backgroundColor: `rgba(${colors.error}, 0.1)`,
    color: colors.error,
    '&:hover': {
      backgroundColor: `rgba(${colors.error}, 0.2)`
    },
    '&.Mui-disabled': {
      color: `rgba(${colors.error}, 0.5)`
    }
  },
  categoryGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '12px',
    maxHeight: '200px',
    overflowY: 'auto',
    marginTop: '16px',
    padding: '8px 12px',
    borderRadius: '8px',
    border: `1px solid ${colors.border}`
  }
})

// Get file list item styling based on status
export const getFileListItemStyle = (status) => {
  const baseStyle = {
    position: 'relative',
    borderRadius: '4px',
    marginBottom: '3px',
    padding: '6px 10px',
    minHeight: '42px',
    height: 'auto',
    transition: 'all 0.2s ease',
    // Consistent height regardless of status
    paddingBottom: '6px'
  }

  let statusStyle = {}
  let primaryTextColor = colors.text
  let secondaryTextColor = 'rgba(0, 0, 0, 0.6)'
  let hoverBgColor = 'rgba(0, 0, 0, 0.04)'

  switch (status) {
    case 'error':
      statusStyle = {
        backgroundColor: `rgba(${colors.error}, 0.05)`
      }
      primaryTextColor = colors.error
      hoverBgColor = `rgba(${colors.error}, 0.1)`
      break
    case 'completed':
      statusStyle = {
        backgroundColor: `rgba(${colors.success}, 0.05)`
      }
      primaryTextColor = colors.success
      hoverBgColor = `rgba(${colors.success}, 0.1)`
      break
    case 'exists':
    case 'skipped':
      statusStyle = {
        backgroundColor: `rgba(${colors.warning}, 0.05)`
      }
      primaryTextColor = colors.warning
      hoverBgColor = `rgba(${colors.warning}, 0.1)`
      break
    case 'uploading':
    case 'processing':
    case 'retrying':
      statusStyle = {
        backgroundColor: 'rgba(25, 118, 210, 0.05)'
      }
      secondaryTextColor = colors.text
      break
    default:
      // Default/pending state
      break
  }

  return {
    ...baseStyle,
    ...statusStyle,
    '&:hover': {
      backgroundColor: hoverBgColor
    },
    '& .MuiListItemText-root': {
      margin: 0,
      padding: 0
    },
    '& .MuiListItemText-primary': {
      color: primaryTextColor,
      fontWeight: '500',
      fontSize: '0.9rem',
      display: 'block',
      lineHeight: '1.3'
    },
    '& .MuiListItemText-secondary': {
      color: secondaryTextColor,
      fontSize: '0.8rem',
      lineHeight: '1.2',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
      marginTop: '2px',
      // For processing files, add margin at bottom for progress bar
      marginBottom: (status === 'uploading' || status === 'processing' || status === 'retrying') ? '12px' : '0px'
    },
    // For items with a progress bar, add extra padding at the bottom
    paddingBottom: (status === 'uploading' || status === 'processing' || status === 'retrying') ? '14px' : '6px'
  }
}

// Get color based on status
export const getStatusColor = (status) => {
  switch (status) {
    case 'completed':
      return colors.success
    case 'error':
      return colors.error
    case 'exists':
    case 'skipped':
      return colors.warning
    default:
      return colors.primary
  }
}

export default {
  useBatchOperationStyles,
  getFileListItemStyle,
  getStatusColor,
  colors
}
