import React, { useState, useEffect, useRef } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Alert,
  Divider,
  LinearProgress,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip,
  Switch
} from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WarningIcon from '@mui/icons-material/Warning'
import DownloadIcon from '@mui/icons-material/Download'
import RefreshIcon from '@mui/icons-material/Refresh'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '../../../../../../firebase/firebase'
import Button from '../../../../../../components/Button/Button'
import { useSelector, useDispatch } from 'react-redux'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { API_ENDPOINTS } from '../../../../../../config/apiConfig'
import { setFileData } from '../../../../../../slices/FolderTreeDataStateSlice'
import { secureDownloadFile } from '../../secureDownload'
import { downloadAnonymizations } from '../../downloadAnonymizations'
import { v4 as uuidv4 } from 'uuid'
import { useBatchOperationStyles, getFileListItemStyle, getStatusColor } from './BatchOperationsStyles'
import '../../../../../../styles/folderTreeData.scss' // Import the SCSS styling for switches

// Define entity types for category selection
const ENTITY_TYPES = [
  'Grafik', 'USERNAME', 'EMAIL_ADDRESS', 'ACCESS_CREDENTIAL', 'GOVERNMENT_ID_NUMBER',
  'HEALTHCARE_IDENTIFIER', 'DATE_OF_BIRTH', 'AGE_INFORMATION', 'FIRST_NAME', 'LAST_NAME',
  'FULL_NAME', 'GENDER', 'MARITAL_STATUS', 'NATIONALITY', 'TELEPHONE_NUMBER',
  'BANK_ACCOUNT_NUMBER', 'ROUTING_NUMBER', 'CREDIT_CARD_INFORMATION', 'IBAN', 'MONEY_AMOUNT',
  'MEDICAL_INFORMATION', 'MEDICAL_CODE', 'DIETARY_RESTRICTION', 'ADDRESS', 'GEOGRAPHIC_COORDINATES',
  'VEHICLE_INFORMATION', 'DATE', 'TIME', 'TIME_PERIOD', 'PROFESSIONAL_INFORMATION',
  'ORGANIZATION_NAME', 'UNIQUE_IDENTIFIER', 'COOKIE_ID', 'SENSITIVE_DEMOGRAPHIC_INFORMATION',
  'SYSTEM_IDENTIFIER', 'URL', 'IP_ADDRESS', 'MAC_ADDRESS', 'GIT_COMMIT_HASH', 'LANGUAGE',
  'EVENT_NAME', 'ENTITY_NAME', 'VERIFICATION_ANSWER', 'ZODIAC_SIGN'
]

function useBatchDownloadLogic (userId, folderId, refreshFolderTree, setContextMenu) {
  const styles = useBatchOperationStyles()

  // Config constants
  const CONCURRENT_DOWNLOAD_LIMIT = 4 // Maximum number of concurrent downloads
  const MAX_RETRIES = 3 // Maximum number of retries for failed downloads

  const [batchDownloadDialogOpen, setBatchDownloadDialogOpen] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [overallProgress, setOverallProgress] = useState(0)
  const [files, setFiles] = useState([])
  const [fileStatuses, setFileStatuses] = useState({})
  const [isClosingBlocked, setIsClosingBlocked] = useState(false)
  const [downloadStats, setDownloadStats] = useState({ total: 0, completed: 0, errors: 0, skipped: 0 })
  const [noFiles, setNoFiles] = useState(false)
  const [noAnonymizedFiles, setNoAnonymizedFiles] = useState(false)
  const [nonAnonymizedCount, setNonAnonymizedCount] = useState(0)
  const [selectedCategories, setSelectedCategories] = useState(ENTITY_TYPES.reduce((acc, type) => {
    acc[type] = true // By default, all categories are selected
    return acc
  }, {}))
  const [isGeneratingZip, setIsGeneratingZip] = useState(false)
  const [showRefreshWarning, setShowRefreshWarning] = useState(false)

  // Refs for download queue management
  const downloadQueueRef = useRef([]) // Queue of file indices to process
  const activeDownloadsRef = useRef(0) // Count of active downloads
  const downloadResultsRef = useRef({}) // Store successful download results

  const userIdToken = useSelector((state) => state.auth.userIdToken)
  const dispatch = useDispatch()
  const fileDataFromRedux = useSelector(state => state.selectedFileSlice)

  // Add getGroupColor function similar to the one in GroupedEntitiesList.js
  const getGroupColor = (status) => {
    switch (status) {
      case 'all': return '#2F4550' // $cadet
      case 'none': return 'rgba(47, 69, 80, 0.38)' // $darkgray-transparent
      case 'partial': return '#586F7C' // lighter variant of $cadet
      default: return 'rgba(47, 69, 80, 0.38)' // $darkgray-transparent
    }
  }

  // Update progress when stats change
  useEffect(() => {
    if (files.length > 0) {
      const processedFiles = downloadStats.completed + downloadStats.errors + downloadStats.skipped
      let overallPercentValue

      if (files.length === processedFiles) {
        overallPercentValue = 100
      } else {
        // Calculate total progress, giving appropriate weight to completed vs in-progress files
        const downloadingFiles = Object.values(fileStatuses).filter(status =>
          status.status === 'processing' || status.status === 'retrying')
        const downloadingProgress = downloadingFiles.reduce((sum, status) => sum + (status.progress || 0), 0)

        const totalProgressValue = (processedFiles * 100 + downloadingProgress) / files.length

        overallPercentValue = Math.min(99, totalProgressValue)
        overallPercentValue = Math.max(overallPercentValue, overallProgress)
      }

      if (Math.abs(overallPercentValue - overallProgress) > 0.5) {
        setOverallProgress(overallPercentValue)
      }
    }
  }, [downloadStats, fileStatuses, files.length, overallProgress])

  // Add effect to handle beforeunload events more consistently
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (processing || isGeneratingZip) {
        // Show custom warning message
        setShowRefreshWarning(true)

        // Standard behavior to prevent closing
        e.preventDefault()
        e.returnValue = 'Download läuft noch. Wenn Sie jetzt die Seite verlassen, wird der Download unterbrochen.'
        return e.returnValue
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [processing, isGeneratingZip])

  const handleBatchDownloadClick = async () => {
    setContextMenu(null)
    setBatchDownloadDialogOpen(true)
    setFiles([])
    setFileStatuses({})
    setOverallProgress(0)
    setIsClosingBlocked(false)
    setDownloadStats({ total: 0, completed: 0, errors: 0, skipped: 0 })
    setNoFiles(false)
    setNoAnonymizedFiles(false)
    setNonAnonymizedCount(0)

    // Fetch PDF files in the selected folder
    try {
      const filesQuery = query(
        collection(db, 'files'),
        where('parent_id', '==', folderId),
        where('user_id', '==', userId)
      )

      const filesSnapshot = await getDocs(filesQuery)
      const pdfFiles = []
      const checkedFiles = []

      filesSnapshot.forEach(doc => {
        const fileData = doc.data()

        // Only include PDF files
        if (fileData.name.toLowerCase().endsWith('.pdf')) {
          checkedFiles.push({
            id: doc.id,
            ...fileData
          })
        }
      })

      console.log('Found PDF files to check:', checkedFiles.length)

      // Check if files have anonymization data available
      for (const file of checkedFiles) {
        // First check if we already have the entities in Redux
        if (fileDataFromRedux[file.id] && fileDataFromRedux[file.id].entities &&
            fileDataFromRedux[file.id].entities.length > 0) {
          // File has anonymization data in Redux
          pdfFiles.push(file)
          continue
        }

        // Check if file has anonymized_entities in Firestore data
        if (file.anonymized_entities) {
          pdfFiles.push(file)
          continue
        }

        // As a last resort, try to check if anonymization data can be loaded
        try {
          const testData = await downloadAnonymizations(userId, file.id)
          if (testData && testData.length > 0) {
            pdfFiles.push(file)
          }
        } catch (error) {
          // Skip this file - it doesn't have valid anonymization data
          console.log(`File ${file.name} has no anonymization data, skipping`)
        }
      }

      // Calculate non-anonymized files count
      const nonAnonymizedFilesCount = checkedFiles.length - pdfFiles.length
      setNonAnonymizedCount(nonAnonymizedFilesCount)

      console.log('Found anonymized PDF files:', pdfFiles.length)
      console.log('Found non-anonymized PDF files:', nonAnonymizedFilesCount)

      if (pdfFiles.length === 0) {
        if (checkedFiles.length === 0) {
          setNoFiles(true)
        } else {
          setNoAnonymizedFiles(true)
        }
      } else {
        setFiles(pdfFiles)
        setDownloadStats({ total: pdfFiles.length, completed: 0, errors: 0, skipped: 0 })
      }
    } catch (error) {
      console.error('Error fetching files:', error)
    }
  }

  const handleCategoryChange = (category) => {
    setSelectedCategories(prev => ({
      ...prev,
      [category]: !prev[category]
    }))
  }

  const selectAllCategories = (value) => {
    setSelectedCategories(
      ENTITY_TYPES.reduce((acc, type) => {
        acc[type] = value
        return acc
      }, {})
    )
  }

  const areAllCategoriesSelected = () => {
    return Object.values(selectedCategories).every(v => v)
  }

  const areSomeCategoriesSelected = () => {
    return Object.values(selectedCategories).some(v => v)
  }

  // Download a single file with retry capability
  const downloadSingleFile = async (file, index, retryCount = 0) => {
    try {
      // Update status to processing or retrying based on retry count
      setFileStatuses(prev => ({
        ...prev,
        [index]: {
          status: retryCount > 0 ? 'retrying' : 'processing',
          progress: 10,
          retryCount: retryCount > 0 ? retryCount : undefined
        }
      }))

      // Check if we have the file in Redux with loaded entities
      let ners = []

      // If the file is in Redux and has entities, use them
      if (fileDataFromRedux[file.id] && fileDataFromRedux[file.id].entities) {
        console.log(`File ${file.name} - Using entities from Redux, count:`, fileDataFromRedux[file.id].entities.length)
        ners = fileDataFromRedux[file.id].entities
      } else { // Otherwise, we need to load them
        console.log(`File ${file.name} - Need to load entities from storage`)

        // First make sure we have the file URL
        let fileUrl = null
        if (fileDataFromRedux[file.id] && fileDataFromRedux[file.id].url) {
          fileUrl = fileDataFromRedux[file.id].url
        } else {
          fileUrl = await secureDownloadFile(userId, file.id)
        }

        // Then load the anonymization data
        let anonymizationData = []
        try {
          anonymizationData = await downloadAnonymizations(userId, file.id)

          // Check if we actually got valid data
          if (!anonymizationData || anonymizationData.length === 0) {
            throw new Error('No anonymization data available')
          }

          // Add unique IDs to entities
          anonymizationData = anonymizationData.map(entity => ({
            ...entity,
            id: uuidv4()
          }))

          // Store in Redux for future use
          dispatch(setFileData({
            ...file,
            url: fileUrl,
            entities: anonymizationData
          }))

          ners = anonymizationData
        } catch (error) {
          console.error(`Failed to load anonymization data for ${file.name}:`, error)
          throw new Error('No anonymization data available for this file')
        }
      }

      // Update progress
      setFileStatuses(prev => ({
        ...prev,
        [index]: {
          status: retryCount > 0 ? 'retrying' : 'processing',
          progress: 30,
          retryCount: retryCount > 0 ? retryCount : undefined
        }
      }))

      // Filter entities based on selected categories
      const filteredEntities = ners.filter(entity => {
        console.log('Checking entity:', entity)

        // Determine entity category from label field first, then fallback to other fields
        let entityCategory = null
        let sourceField = null

        // First check if entity has a label field - this should be our primary matching field
        if (entity.label) {
          entityCategory = entity.label.toUpperCase()
          sourceField = 'label'
        } else if (entity.type) { // If no label, use type field
          entityCategory = entity.type.toUpperCase()
          sourceField = 'type'
        } else if (entity.category) { // Last resort, use category field if present
          entityCategory = entity.category.toUpperCase()
          sourceField = 'category'
        } else { // Default fallback
          entityCategory = 'PII'
          sourceField = 'default'
        }

        console.log(`Entity category determined as: ${entityCategory} (from ${sourceField} field)`)

        // Now check if this category is selected by the user (case-insensitive)
        let isSelected = false
        let matchedCategory = null

        for (const [category, selected] of Object.entries(selectedCategories)) {
          // Skip categories not selected by user
          if (!selected) continue

          const categoryUpper = category.toUpperCase()

          // Compare both in uppercase for case-insensitivity
          if (categoryUpper === entityCategory) {
            isSelected = true
            matchedCategory = category
            break
          }

          // For FULL_NAME, we do additional matching for person names
          if (categoryUpper === 'FULL_NAME' && entity.text &&
              /^[A-Z][a-z]+ [A-Z][a-z]+$/.test(entity.text)) {
            isSelected = true
            matchedCategory = category
            break
          }
        }

        // Special handling for TEXT type entities that contain names
        if (entityCategory === 'TEXT' && entity.text) {
          // Check if this looks like a name
          if (/^[A-Z][a-z]+ [A-Z][a-z]+$/.test(entity.text)) {
            if (selectedCategories.FULL_NAME) {
              isSelected = true
              matchedCategory = 'FULL_NAME'
            }
          }
        }

        console.log(`Is selected: ${isSelected}${matchedCategory ? ` (matched with ${matchedCategory})` : ''}`)

        return isSelected
      })
      console.log(`File ${file.name} - Filtered entities:`, filteredEntities)

      // If no entities match the selected categories, mark as skipped but still process with a placeholder
      let formattedEntities = []
      if (filteredEntities.length === 0) {
        setFileStatuses(prev => ({
          ...prev,
          [index]: { status: 'skipped', progress: 0, reason: 'No matching categories' }
        }))

        // Create a placeholder entity that won't affect the PDF visibly
        // This ensures we can still make the API call and download the file
        formattedEntities = [{
          bbox: {
            x0: 100,
            x1: 100,
            y0: 100,
            y1: 100
          },
          label: 'PII',
          page: 1,
          text: 's'
        }]

        // Update download stats for tracking
        setDownloadStats(prev => ({
          ...prev,
          skipped: prev.skipped + 1
        }))
      } else {
        // Prepare entities for the redact API in the expected format
        formattedEntities = filteredEntities.map(entity => ({
          bbox: {
            x0: entity.bbox.x0 || entity.bbox[0],
            x1: entity.bbox.x1 || entity.bbox[2],
            y0: entity.bbox.y0 || entity.bbox[1],
            y1: entity.bbox.y1 || entity.bbox[3]
          },
          label: entity.type || entity.category || 'PII',
          page: entity.page,
          text: 's'
        }))
      }

      // Update progress
      setFileStatuses(prev => ({
        ...prev,
        [index]: {
          status: retryCount > 0 ? 'retrying' : 'processing',
          progress: 50,
          retryCount: retryCount > 0 ? retryCount : undefined
        }
      }))

      // Construct the URL with query parameters
      const apiUrl = `${API_ENDPOINTS.redactPDF}?file_id=${encodeURIComponent(file.id)}`

      // Make API request
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userIdToken}`,
          accept: 'application/json'
        },
        body: JSON.stringify({ entities: formattedEntities })
      })

      // Update progress
      setFileStatuses(prev => ({
        ...prev,
        [index]: {
          status: retryCount > 0 ? 'retrying' : 'processing',
          progress: 90,
          retryCount: retryCount > 0 ? retryCount : undefined
        }
      }))

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      // Get the file data as blob
      const blob = await response.blob()

      // Mark file as completed
      setFileStatuses(prev => ({
        ...prev,
        [index]: { status: 'completed', progress: 100, blob }
      }))

      // Update stats for completed file
      setDownloadStats(prev => ({
        ...prev,
        completed: prev.completed + 1
      }))

      // Store result for ZIP creation
      const result = {
        blob,
        name: file.name.replace('.pdf', '_redacted.pdf')
      }
      downloadResultsRef.current[index] = result

      // Process next file in queue if any
      activeDownloadsRef.current--
      processDownloadQueue()

      return result
    } catch (error) {
      console.error(`Error downloading file ${file.name}${retryCount > 0 ? ` (Attempt ${retryCount + 1}/${MAX_RETRIES + 1})` : ''}:`, error)

      // Implement retry logic with exponential backoff
      if (retryCount < MAX_RETRIES) {
        // We'll retry this file
        console.log(`Retrying download for ${file.name} (Attempt ${retryCount + 1}/${MAX_RETRIES})`)

        setFileStatuses(prev => ({
          ...prev,
          [index]: {
            status: 'retrying',
            error: `${error.message} - Versuch ${retryCount + 1}/${MAX_RETRIES}`,
            retryCount
          }
        }))

        // Wait a bit before retrying (exponential backoff)
        const delay = Math.min(1000 * Math.pow(2, retryCount), 10000)
        await new Promise(resolve => setTimeout(resolve, delay))

        // Try again
        return downloadSingleFile(file, index, retryCount + 1)
      } else {
        // We've reached max retries
        setFileStatuses(prev => ({
          ...prev,
          [index]: {
            status: 'error',
            error: `${error.message} - Nach ${MAX_RETRIES} Versuchen fehlgeschlagen`,
            retryCount
          }
        }))

        // Update stats for error
        setDownloadStats(prev => ({
          ...prev,
          errors: prev.errors + 1
        }))

        // Process next file in queue if any
        activeDownloadsRef.current--
        processDownloadQueue()

        return null
      }
    }
  }

  // Process the download queue with concurrent downloads
  const processDownloadQueue = () => {
    // Keep starting new downloads until we reach the limit or run out of files
    while (downloadQueueRef.current.length > 0 && activeDownloadsRef.current < CONCURRENT_DOWNLOAD_LIMIT) {
      const fileIndex = downloadQueueRef.current.shift()
      activeDownloadsRef.current++

      // Start this file download asynchronously
      downloadSingleFile(files[fileIndex], fileIndex)
        .catch(err => {
          console.error('Error in download:', err)
          // Error handling is already done within downloadSingleFile
        })
    }

    // Check if we're completely done
    if (downloadQueueRef.current.length === 0 && activeDownloadsRef.current === 0) {
      // All downloads are complete, create the ZIP file
      createAndDownloadZip()
    }
  }

  // Function to create and download the ZIP file after all downloads complete
  const createAndDownloadZip = async () => {
    try {
      // Get the successful download results
      const results = Object.values(downloadResultsRef.current).filter(Boolean)

      // If we have results, create a zip file
      if (results.length > 0) {
        setIsGeneratingZip(true)

        // Create a new JSZip instance
        const zip = new JSZip()

        // Add each blob to the zip
        results.forEach(result => {
          zip.file(result.name, result.blob)
        })

        // Generate the zip file
        const zipBlob = await zip.generateAsync({ type: 'blob' })

        // Download the zip file
        saveAs(zipBlob, 'anonymized_files.zip')
      }
    } catch (error) {
      console.error('Error creating ZIP file:', error)
    } finally {
      // Clean up
      setIsGeneratingZip(false)
      setProcessing(false)
      setIsClosingBlocked(false)

      // Clear any lingering beforeunload handlers
      const cleanupFunction = () => { return undefined }
      window.onbeforeunload = null
      window.addEventListener('beforeunload', cleanupFunction)
      window.removeEventListener('beforeunload', cleanupFunction)
    }
  }

  // Function to retry a failed file download
  const retryFile = async (index) => {
    if (!files[index]) return

    const newStatuses = { ...fileStatuses }
    newStatuses[index] = { status: 'processing', progress: 0 }
    setFileStatuses(newStatuses)

    // Update stats for retry
    if (fileStatuses[index]?.status === 'error') {
      setDownloadStats(prev => ({
        ...prev,
        errors: Math.max(0, prev.errors - 1)
      }))
    }

    try {
      setProcessing(true)
      setIsClosingBlocked(true)

      // Process the single file directly
      const result = await downloadSingleFile(files[index], index, 0)

      if (result) {
        // If this was the only remaining file, create ZIP
        if (downloadStats.completed + 1 === files.length - downloadStats.skipped) {
          await createAndDownloadZip()
        }
      } else {
        setProcessing(false)
        setIsClosingBlocked(false)
      }
    } catch (error) {
      console.error('Error retrying file:', error)
      setProcessing(false)
      setIsClosingBlocked(false)
    }
  }

  const handleDownloadFiles = async () => {
    if (files.length === 0) return

    setProcessing(true)
    setIsClosingBlocked(true)

    // Reset download results storage
    downloadResultsRef.current = {}

    try {
      // Initialize the queue with all file indices
      downloadQueueRef.current = Array.from({ length: files.length }, (_, i) => i)
      activeDownloadsRef.current = 0

      // Start the download process with concurrent downloads
      processDownloadQueue()
    } catch (error) {
      console.error('Error in batch download:', error)
      setProcessing(false)
      setIsClosingBlocked(false)
    }
  }

  // Add a helper function to handle close button clicks
  const handleCloseButtonClick = () => {
    if (isClosingBlocked) {
      // Show warning if processing is running
      setShowRefreshWarning(true)
    } else {
      handleClose()
    }
  }

  const handleClose = () => {
    if (!isClosingBlocked) {
      setBatchDownloadDialogOpen(false)
      setFiles([])
      setFileStatuses({})
      setOverallProgress(0)
      setDownloadStats({ total: 0, completed: 0, errors: 0, skipped: 0 })
    }
  }

  const getStatusIcon = (status) => {
    switch (status) {
      case 'completed':
        return <CheckCircleIcon sx={{ color: getStatusColor('completed') }} />
      case 'error':
        return <ErrorIcon sx={{ color: getStatusColor('error') }} />
      case 'skipped':
        return <WarningIcon sx={{ color: getStatusColor('skipped') }} />
      case 'processing':
        return <CircularProgress size={20} sx={{ color: getStatusColor('processing') }} />
      case 'retrying':
        return <RefreshIcon sx={{ color: getStatusColor('processing') }} />
      default:
        return null
    }
  }

  const getStatusText = (file, index) => {
    const status = fileStatuses[index]?.status

    if (!status) return 'Warten'

    switch (status) {
      case 'processing':
        return `Wird heruntergeladen (${Math.round(fileStatuses[index]?.progress || 0)}%)`
      case 'retrying':
        return `Erneuter Versuch wird gestartet... (${fileStatuses[index]?.retryCount + 1}/${MAX_RETRIES + 1})`
      case 'completed':
        return 'Download abgeschlossen'
      case 'error':
        return `Fehler: ${fileStatuses[index]?.error || 'Unbekannter Fehler'}`
      case 'skipped':
        return fileStatuses[index]?.reason || 'Übersprungen'
      default:
        return status
    }
  }

  const allProcessed = files.length > 0 &&
    downloadStats.completed + downloadStats.errors + downloadStats.skipped === files.length

  const selectedCategoriesCount = Object.values(selectedCategories).filter(Boolean).length

  // Function to display statistics for large file sets
  const LargeFileSetStats = () => {
    if (files.length < 20) return null // Only show for larger file sets

    const pendingCount = files.length -
      (downloadStats.completed + downloadStats.errors + downloadStats.skipped) -
      Object.values(fileStatuses).filter(s => s?.status === 'processing' || s?.status === 'retrying').length

    const processingCount = Object.values(fileStatuses).filter(
      s => s?.status === 'processing' || s?.status === 'retrying'
    ).length

    return (
      <Box sx={{ mt: 2, mb: 1, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
        <Typography variant="subtitle2" gutterBottom>
          Download-Status für {files.length} Dateien:
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', mb: 1 }}>
          <Typography variant="body2">
            <strong>Warten:</strong> {pendingCount}
          </Typography>
          <Typography variant="body2">
            <strong>In Bearbeitung:</strong> {processingCount}
          </Typography>
          <Typography variant="body2">
            <strong>Abgeschlossen:</strong> {downloadStats.completed}
          </Typography>
          <Typography variant="body2">
            <strong>Fehler:</strong> {downloadStats.errors}
          </Typography>
          <Typography variant="body2">
            <strong>Übersprungen:</strong> {downloadStats.skipped}
          </Typography>
        </Box>
        {processing && (
          <Typography variant="caption" sx={{ display: 'block', mt: 1, color: 'text.secondary' }}>
            Die Dateien werden in Batches von {CONCURRENT_DOWNLOAD_LIMIT} gleichzeitig heruntergeladen, um die Serverressourcen zu schonen.
          </Typography>
        )}
      </Box>
    )
  }

  const BatchDownloadDialog = (
    <>
      <Dialog
        open={batchDownloadDialogOpen}
        onClose={(event, reason) => {
          // Only handle non-disruptive close events
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleCloseButtonClick()
          } else if (isClosingBlocked) {
            // For backdrop and escape key, show warning if download is in progress
            setShowRefreshWarning(true)
          }
        }}
        maxWidth="lg"
        fullWidth
        disableEscapeKeyDown={isClosingBlocked}
        PaperProps={{
          sx: {
            minWidth: '900px',
            maxHeight: '90vh'
          }
        }}
      >
        {(processing || isGeneratingZip) && <LinearProgress variant="determinate" value={overallProgress} className={styles.overallProgress} />}
        <DialogTitle className={styles.dialogTitle}>
          Batch Download Anonymisierter PDFs
          {processing && (
            <Typography variant="subtitle2" color="textSecondary" className="subtitle">
              Bitte Dialog nicht schließen. Download läuft...
            </Typography>
          )}
          {isGeneratingZip && (
            <Typography variant="subtitle2" color="textSecondary" className="subtitle">
              ZIP-Datei wird generiert...
            </Typography>
          )}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Laden Sie anonymisierte PDF-Dateien im ausgewählten Ordner basierend auf Kategorien herunter.
          </Typography>

          {noFiles ? (
            <Alert severity="info" style={{ marginTop: '20px' }}>
              Keine PDF-Dateien im ausgewählten Ordner gefunden.
            </Alert>
          ) : noAnonymizedFiles ? (
            <Alert severity="info" style={{ marginTop: '20px' }}>
              Keine anonymisierten PDF-Dateien im ausgewählten Ordner gefunden.
            </Alert>
          ) : (
            <>
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" fontWeight="bold">
                  Zu anonymisierende Entitäten auswählen:
                </Typography>
                <FormGroup row style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="toggle-item">
                    <Switch
                      checked={areAllCategoriesSelected()}
                      onChange={() => selectAllCategories(!areAllCategoriesSelected())}
                      disabled={processing || isGeneratingZip}
                      size="small"
                      indeterminate={!areAllCategoriesSelected() && areSomeCategoriesSelected()}
                    />
                  </div>
                  <Typography
                    variant="subtitle1"
                    style={{
                      marginLeft: '8px',
                      color: getGroupColor(areAllCategoriesSelected() ? 'all' : areSomeCategoriesSelected() ? 'partial' : 'none'),
                      fontWeight: 'bold'
                    }}
                  >
                    Alle Kategorien
                  </Typography>
                </FormGroup>

                <Box className={styles.categoryGrid}>
                  {ENTITY_TYPES.map(category => (
                    <div
                      key={category}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '4px 8px'
                      }}
                    >
                      <div className="toggle-item">
                        <Switch
                          checked={selectedCategories[category] || false}
                          onChange={() => handleCategoryChange(category)}
                          disabled={processing || isGeneratingZip}
                          size="small"
                        />
                      </div>
                      <Typography variant="body2" style={{ marginLeft: '8px' }}>
                        {category}
                      </Typography>
                    </div>
                  ))}
                </Box>

                <Typography variant="body2" sx={{ mt: 1 }}>
                  {selectedCategoriesCount} von {ENTITY_TYPES.length} Kategorien ausgewählt
                </Typography>
              </Box>

              {files.length > 0 && (
                <>
                  <Box className={styles.progressContainer}>
                    <Box className={styles.statusBox} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Gesamtfortschritt: {Math.round(overallProgress)}%
                      </Typography>
                      <Typography variant="body2">
                        {downloadStats.completed} von {files.length} abgeschlossen
                        {processing ? ' (Läuft...)' : allProcessed ? ' (Abgeschlossen)' : ' (Bereit)'}
                      </Typography>
                    </Box>
                  </Box>

                  <Divider style={{ margin: '16px 0' }} />
                  <Typography variant="subtitle1" fontWeight="bold">
                    Ausgewählte Dateien ({files.length} anonymisierte und {nonAnonymizedCount} nicht anonymisierte Dateien)
                  </Typography>

                  <Paper elevation={1} className={styles.fileStatusPaper}>
                    <List dense disablePadding>
                      {files.map((file, index) => (
                        <ListItem
                          key={`${file.id}-${index}`}
                          sx={getFileListItemStyle(fileStatuses[index]?.status || 'pending')}
                        >
                          <Box className={styles.statusIconBox}>
                            {getStatusIcon(fileStatuses[index]?.status)}
                          </Box>
                          <ListItemText
                            primary={file.name}
                            secondary={getStatusText(file, index)}
                          />
                          {fileStatuses[index]?.status === 'completed' && (
                            <Tooltip title="Einzeln herunterladen">
                              <IconButton
                                edge="end"
                                size="small"
                              >
                                <DownloadIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {fileStatuses[index]?.status === 'error' && (
                            <Tooltip title="Erneut versuchen">
                              <IconButton
                                edge="end"
                                size="small"
                                onClick={() => retryFile(index)}
                                disabled={processing || isGeneratingZip}
                              >
                                <RefreshIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </ListItem>
                      ))}
                    </List>
                  </Paper>
                </>
              )}

              {/* Show statistics for large file sets */}
              <LargeFileSetStats />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseButtonClick}
            disabled={false} // Never disable the close button
            isCTA={!isClosingBlocked}
            label={processing || isGeneratingZip ? 'Bitte warten...' : 'Schließen'}
          />
          {!noFiles && !noAnonymizedFiles && (
            <Button
              onClick={handleDownloadFiles}
              disabled={files.length === 0 || processing || isGeneratingZip || allProcessed || !areSomeCategoriesSelected()}
              isCTA={files.length > 0 && !processing && !isGeneratingZip && !allProcessed && areSomeCategoriesSelected()}
              label={processing ? 'Läuft...' : isGeneratingZip ? 'ZIP wird erstellt...' : 'Herunterladen'}
            />
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={showRefreshWarning && (processing || isGeneratingZip)}
        onClose={() => setShowRefreshWarning(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ color: getStatusColor('error') }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <WarningIcon />
            Achtung: Download läuft
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Es laufen noch Downloads. Wenn Sie jetzt die Seite verlassen oder den Dialog schließen, werden alle laufenden Downloads abgebrochen.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Bitte warten Sie, bis alle Downloads abgeschlossen sind, bevor Sie die Seite verlassen.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowRefreshWarning(false)}
            disabled={false}
            isCTA={true}
            label="Verstanden"
          />
        </DialogActions>
      </Dialog>
    </>
  )

  return { handleBatchDownloadClick, BatchDownloadDialog }
}

export default useBatchDownloadLogic
