import React, { useState, useRef, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  IconButton,
  Tooltip,
  Box,
  Alert,
  Divider,
  CircularProgress
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RefreshIcon from '@mui/icons-material/Refresh'
import WarningIcon from '@mui/icons-material/Warning'
import { ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from '../../../../../../firebase/firebase'
import { encryptData } from './Utils'
import { getFirestore, collection, doc, setDoc, Timestamp, getDocs, query, where } from 'firebase/firestore'
import Button from '../../../../../../components/Button/Button'
import { useBatchOperationStyles, getFileListItemStyle, getStatusColor } from './BatchOperationsStyles'
import { createFolder } from '../../../../../../firebase/userOperations/createFolder'

function useBatchUploadLogic (userId, parentId, refreshFolderTree, setContextMenu) {
  const styles = useBatchOperationStyles()

  // Config constants
  const CONCURRENT_UPLOAD_LIMIT = 5 // Maximum number of concurrent uploads
  const MAX_RETRIES = 3 // Maximum number of retries for failed uploads
  const MAX_FILE_SIZE = 30 * 1024 * 1024 // 30MB file size limit

  const [batchUploadDialogOpen, setBatchUploadDialogOpen] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [overallProgress, setOverallProgress] = useState(0)
  const [files, setFiles] = useState([])
  const [fileStatuses, setFileStatuses] = useState({}) // Track status of each file
  const [existingFiles, setExistingFiles] = useState([]) // Track files that already exist
  const [oversizedFiles, setOversizedFiles] = useState([]) // Track files that are too large
  const [isClosingBlocked, setIsClosingBlocked] = useState(false)
  const [uploadStats, setUploadStats] = useState({ total: 0, completed: 0, errors: 0, skipped: 0 })
  const [showRefreshWarning, setShowRefreshWarning] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [isFolderUpload, setIsFolderUpload] = useState(false) // Track if we're uploading a folder
  const [folderName, setFolderName] = useState('') // Store the folder name
  const [newFolderId, setNewFolderId] = useState(null) // Store the new folder ID if created

  const fileInputRef = useRef(null)
  const folderInputRef = useRef(null) // Add reference for folder input
  const db = getFirestore()
  const uploadQueueRef = useRef([]) // Queue of file indices to upload
  const activeUploadsRef = useRef(0) // Count of active uploads
  const uploadTasksRef = useRef({}) // Store upload tasks for cancellation if needed

  // Clean up function to ensure upload tasks are properly released
  useEffect(() => {
    return () => {
      // Clean up any upload tasks on component unmount
      Object.values(uploadTasksRef.current).forEach(task => {
        try {
          if (task && typeof task.cancel === 'function') {
            task.cancel()
          }
        } catch (err) {
          console.error('Error cancelling task:', err)
        }
      })
    }
  }, [])

  // Prevent window closing during upload
  useEffect(() => {
    // Only add the event listener when actually uploading
    if (!uploading) return

    const handleBeforeUnload = (e) => {
      // Show custom warning message
      setShowRefreshWarning(true)

      // Standard behavior to prevent closing
      e.preventDefault()
      e.returnValue = 'Uploads laufen noch. Wenn Sie jetzt die Seite verlassen, werden Ihre Uploads unterbrochen.'
      return e.returnValue
    }

    // Add the event listener only when uploading is true
    window.addEventListener('beforeunload', handleBeforeUnload)

    // Clean up function that removes the listener when uploading changes to false or component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [uploading]) // This effect depends only on the uploading state

  // Update progress immediately when stats change
  useEffect(() => {
    if (files.length > 0) {
      // Use a more stable calculation based on completed files
      const completedFiles = uploadStats.completed + uploadStats.errors + uploadStats.skipped

      // Calculate progress for uploading files - sum up their individual progress
      const uploadingFiles = Object.values(fileStatuses).filter(status => status.status === 'uploading')
      const uploadingFilesCount = uploadingFiles.length
      const uploadingProgress = uploadingFiles.reduce((sum, status) => sum + (status.progress || 0), 0)

      // Calculate the weighted progress: completed files at 100% + current progress of uploading files
      let overallPercentValue
      if (files.length === completedFiles) {
        // If all files are completed/errored/skipped, set to 100%
        overallPercentValue = 100
      } else {
        // Calculate total progress, giving appropriate weight to completed vs in-progress files
        const totalProgressValue = (completedFiles * 100) + uploadingProgress
        overallPercentValue = Math.min(99, (totalProgressValue / files.length))

        // Ensure progress never goes backward
        overallPercentValue = Math.max(overallPercentValue, overallProgress)
      }

      // Only update if the change is significant (prevents small fluctuations)
      if (Math.abs(overallPercentValue - overallProgress) > 0.5) {
        setOverallProgress(overallPercentValue)
      }
    }
  }, [uploadStats.completed, uploadStats.errors, uploadStats.skipped, files.length, fileStatuses])

  const handleBatchUploadClick = () => {
    setContextMenu(null)
    setBatchUploadDialogOpen(true)
    setFiles([])
    setFileStatuses({})
    setExistingFiles([])
    setOversizedFiles([])
    setOverallProgress(0)
    setIsClosingBlocked(false)
    setUploading(false)
    setShowSuccessMessage(false)
    setUploadStats({ total: 0, completed: 0, errors: 0, skipped: 0 })
  }

  const checkFileExists = async (fileName, userId, parentId) => {
    const filesRef = collection(db, 'files')
    const querySnapshot = await getDocs(
      query(
        filesRef,
        where('name', '==', fileName),
        where('user_id', '==', userId),
        where('parent_id', '==', parentId)
      )
    )
    return !querySnapshot.empty
  }

  const handleFileChange = (e) => {
    // Reset any previous upload process
    setShowSuccessMessage(false)
    setIsFolderUpload(false) // Not a folder upload
    setFolderName('')
    setNewFolderId(null)
    setOversizedFiles([])

    // Filter out non-PDF files and check file sizes
    const selectedFiles = Array.from(e.target.files).filter(file => file.type === 'application/pdf')
    const oversized = []
    const validFiles = []

    // Sort files by size check
    selectedFiles.forEach(file => {
      if (file.size > MAX_FILE_SIZE) {
        oversized.push(file)
      } else {
        validFiles.push(file)
      }
    })

    // Store oversized files for warning display
    if (oversized.length > 0) {
      setOversizedFiles(oversized)
    }

    console.log('Files selected:', selectedFiles.length, 'Oversized:', oversized.length)

    // Reset statuses when selecting new files
    setFileStatuses({})
    setExistingFiles([])
    setOverallProgress(0)

    // Set initial status for oversized files
    const initialStatuses = {}
    const allFiles = [...validFiles, ...oversized]

    oversized.forEach((file, idx) => {
      const index = validFiles.length + idx
      initialStatuses[index] = {
        status: 'error',
        error: 'Datei zu groß (>30MB)',
        isRetryable: false
      }
    })

    setFileStatuses(initialStatuses)
    setUploadStats({
      total: allFiles.length,
      completed: 0,
      errors: oversized.length,
      skipped: 0
    })

    if (allFiles.length > 0) {
      setFiles(allFiles)
    }
  }

  // Handle folder selection (for browsers that support it)
  const handleFolderChange = (e) => {
    // Reset any previous upload process
    setShowSuccessMessage(false)
    setNewFolderId(null)
    setOversizedFiles([])

    // Get all files from the folder selection
    const selectedFiles = Array.from(e.target.files).filter(file => file.type === 'application/pdf')
    const oversized = []
    const validFiles = []

    // Sort files by size check
    selectedFiles.forEach(file => {
      if (file.size > MAX_FILE_SIZE) {
        oversized.push(file)
      } else {
        validFiles.push(file)
      }
    })

    // Store oversized files for warning display
    if (oversized.length > 0) {
      setOversizedFiles(oversized)
    }

    console.log('Folder selected, found PDF files:', selectedFiles.length, 'Oversized:', oversized.length)

    // Check if there are any PDF files
    if (selectedFiles.length === 0) {
      return
    }

    // Extract folder name
    const extractedFolderName = extractFolderNameFromFiles(e.target.files)

    if (extractedFolderName) {
      console.log('Detected folder name:', extractedFolderName)
      setFolderName(extractedFolderName)
      setIsFolderUpload(true)
    } else {
      setIsFolderUpload(false)
      setFolderName('')
    }

    // Reset statuses when selecting new files
    setFileStatuses({})
    setExistingFiles([])
    setOverallProgress(0)

    // Set initial status for oversized files
    const initialStatuses = {}
    const allFiles = [...validFiles, ...oversized]

    oversized.forEach((file, idx) => {
      const index = validFiles.length + idx
      initialStatuses[index] = {
        status: 'error',
        error: 'Datei zu groß (>30MB)',
        isRetryable: false
      }
    })

    setFileStatuses(initialStatuses)
    setUploadStats({
      total: allFiles.length,
      completed: 0,
      errors: oversized.length,
      skipped: 0
    })

    if (allFiles.length > 0) {
      setFiles(allFiles)
    }
  }

  const removeFile = (index) => {
    const newFiles = [...files]
    newFiles.splice(index, 1)
    setFiles(newFiles)

    // Also remove this file from statuses
    const newFileStatuses = { ...fileStatuses }
    const removedStatus = newFileStatuses[index]
    delete newFileStatuses[index]
    setFileStatuses(newFileStatuses)

    // Update stats
    setUploadStats(prev => {
      const newStats = { ...prev, total: prev.total - 1 }

      // If removing a file with error status, decrement error count
      if (removedStatus && removedStatus.status === 'error') {
        newStats.errors = Math.max(0, prev.errors - 1)
      }

      return newStats
    })

    // Also update oversizedFiles if needed
    if (files[index] && files[index].size > MAX_FILE_SIZE) {
      setOversizedFiles(prev => prev.filter(f => f.name !== files[index].name))
    }
  }

  const retryFile = async (index) => {
    if (!files[index]) return

    const newStatuses = { ...fileStatuses }
    newStatuses[index] = { status: 'uploading', progress: 0 }
    setFileStatuses(newStatuses)

    // Update stats for retry
    if (fileStatuses[index]?.status === 'error') {
      setUploadStats(prev => ({
        ...prev,
        errors: Math.max(0, prev.errors - 1)
      }))
    }

    await uploadSingleFile(files[index], index, 0) // Start with retry count 0
  }

  const uploadSingleFile = async (file, index, retryCount = 0, targetParentId = parentId) => {
    try {
      // Check if file is oversized
      if (file.size > MAX_FILE_SIZE) {
        setFileStatuses(prev => ({
          ...prev,
          [index]: {
            status: 'error',
            error: 'Datei zu groß (>30MB)',
            isRetryable: false
          }
        }))

        // Update stats for error file
        setUploadStats(prev => ({
          ...prev,
          errors: prev.errors + 1
        }))

        // Process next file in queue if any
        activeUploadsRef.current--
        processUploadQueue(targetParentId)
        return false
      }

      // Check if file already exists
      const fileExists = await checkFileExists(file.name, userId, targetParentId)
      if (fileExists) {
        // Mark file as existing and skip
        if (!existingFiles.includes(file.name)) {
          setExistingFiles(prev => [...prev, file.name])
        }

        setFileStatuses(prev => ({
          ...prev,
          [index]: { status: 'exists', progress: 0 }
        }))

        // Update stats for skipped file
        setUploadStats(prev => ({
          ...prev,
          skipped: prev.skipped + 1
        }))

        // Process next file in queue if any
        activeUploadsRef.current--
        processUploadQueue(targetParentId)
        return false
      }

      // Update status to uploading
      setFileStatuses(prev => ({
        ...prev,
        [index]: {
          status: 'uploading',
          progress: 0,
          retryCount
        }
      }))

      // Read file and encrypt data
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.onload = async (e) => {
          try {
            const arrayBuffer = e.target.result
            const { encryptedData, rawKey, iv } = await encryptData(arrayBuffer)

            // Create document reference
            const filesRef = collection(db, 'files')
            const fileDocumentRef = doc(filesRef)

            // Create storage reference
            const fileRef = ref(storage, `userFiles/${userId}/${targetParentId}/${file.name}`)

            // Upload encrypted data
            const uploadTask = uploadBytesResumable(fileRef, new Uint8Array(encryptedData))
            uploadTasksRef.current[index] = uploadTask // Store reference to task

            // Track progress updates less frequently to avoid UI jitter
            let lastProgressUpdate = 0

            uploadTask.on(
              'state_changed',
              (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

                // Only update progress if it's changed by at least 1% to reduce UI updates
                if (progress - lastProgressUpdate >= 1 || progress === 100) {
                  lastProgressUpdate = progress

                  // Update the progress for this specific file
                  setFileStatuses(prev => ({
                    ...prev,
                    [index]: {
                      status: 'uploading',
                      progress,
                      retryCount
                    }
                  }))
                }
              },
              async (error) => {
                // Handle error with retry logic
                console.error(`Upload error for file ${file.name}:`, error.message)

                if (retryCount < MAX_RETRIES) {
                  // We'll retry this file
                  console.log(`Retrying upload for ${file.name} (Attempt ${retryCount + 1}/${MAX_RETRIES})`)

                  setFileStatuses(prev => ({
                    ...prev,
                    [index]: {
                      status: 'retrying',
                      error: `${error.message} - Versuch ${retryCount + 1}/${MAX_RETRIES}`
                    }
                  }))

                  // Wait a bit before retrying (exponential backoff)
                  const delay = Math.min(1000 * Math.pow(2, retryCount), 10000)
                  await new Promise(resolve => setTimeout(resolve, delay))

                  // Try again
                  const retryResult = await uploadSingleFile(file, index, retryCount + 1, targetParentId)
                  resolve(retryResult)
                } else {
                  // We've reached max retries
                  setFileStatuses(prev => ({
                    ...prev,
                    [index]: {
                      status: 'error',
                      error: `${error.message} - Nach ${MAX_RETRIES} Versuchen fehlgeschlagen`
                    }
                  }))

                  // Update stats for error
                  setUploadStats(prev => ({
                    ...prev,
                    errors: prev.errors + 1
                  }))

                  // Process next file in queue if any
                  activeUploadsRef.current--
                  processUploadQueue(targetParentId)
                  reject(error)
                }
              },
              async () => {
                // Save the file document
                const fileDocument = {
                  id: fileDocumentRef.id,
                  name: file.name,
                  user_id: userId,
                  parent_id: targetParentId,
                  cryptoKey: Array.from(rawKey),
                  iv: Array.from(iv),
                  created_at: Timestamp.now()
                }

                await setDoc(fileDocumentRef, fileDocument)

                // Update the status to completed
                setFileStatuses(prev => ({
                  ...prev,
                  [index]: { status: 'completed', progress: 100 }
                }))

                // Update stats for completed file
                setUploadStats(prev => ({
                  ...prev,
                  completed: prev.completed + 1
                }))

                // Process next file in queue if any
                activeUploadsRef.current--
                processUploadQueue(targetParentId)
                resolve(true)
              }
            )
          } catch (error) {
            setFileStatuses(prev => ({
              ...prev,
              [index]: { status: 'error', error: error.message }
            }))

            // Update stats for error
            setUploadStats(prev => ({
              ...prev,
              errors: prev.errors + 1
            }))

            // Process next file in queue if any
            activeUploadsRef.current--
            processUploadQueue(targetParentId)
            reject(error)
          }
        }

        fileReader.onerror = (error) => {
          setFileStatuses(prev => ({
            ...prev,
            [index]: { status: 'error', error: 'Error reading file' }
          }))

          // Update stats for error
          setUploadStats(prev => ({
            ...prev,
            errors: prev.errors + 1
          }))

          // Process next file in queue if any
          activeUploadsRef.current--
          processUploadQueue(targetParentId)
          reject(error)
        }

        fileReader.readAsArrayBuffer(file)
      })
    } catch (error) {
      setFileStatuses(prev => ({
        ...prev,
        [index]: { status: 'error', error: error.message }
      }))

      // Update stats for error
      setUploadStats(prev => ({
        ...prev,
        errors: prev.errors + 1
      }))

      // Process next file in queue if any
      activeUploadsRef.current--
      processUploadQueue(targetParentId)
      return false
    }
  }

  // Process the upload queue by starting uploads up to the concurrent limit
  const processUploadQueue = (targetParentId = parentId) => {
    while (uploadQueueRef.current.length > 0 && activeUploadsRef.current < CONCURRENT_UPLOAD_LIMIT) {
      const fileIndex = uploadQueueRef.current.shift()
      activeUploadsRef.current++

      // Start this file upload asynchronously
      uploadSingleFile(files[fileIndex], fileIndex, 0, targetParentId).catch(err => {
        console.error('Error in upload:', err)
        // Error handling is already done within uploadSingleFile
      })
    }

    // Check if we're completely done
    if (uploadQueueRef.current.length === 0 && activeUploadsRef.current === 0 && uploading) {
      // Capture the completion state for checking if files were successfully uploaded
      const anySuccess = Object.values(fileStatuses).some(status =>
        status.status === 'completed'
      )

      // Update state variables to indicate upload is complete
      setUploading(false)
      setIsClosingBlocked(false)

      if (anySuccess) {
        setShowSuccessMessage(true)
      }

      // Always refresh the folder tree when uploads complete
      refreshFolderTree()

      // For extra safety, call it again after a short delay
      setTimeout(() => {
        refreshFolderTree()
      }, 500)

      // Reset both file inputs to allow choosing new files
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }

      if (folderInputRef.current) {
        folderInputRef.current.value = ''
      }

      // Reset states to allow for a new batch upload, but keep the dialog open
      setFiles([])
      setFileStatuses({})
      setExistingFiles([])
      setOverallProgress(0)
      setUploadStats({ total: 0, completed: 0, errors: 0, skipped: 0 })
      setIsFolderUpload(false)
      setFolderName('')
      // Don't reset newFolderId here so we can include it in success message

      // Explicitly trigger component re-render by setting state multiple times
      // This ensures all UI elements - especially buttons - properly update
      setTimeout(() => {
        // Update button state by forcing these state changes
        setUploading(false)
        setIsClosingBlocked(false)

        // The second timeout ensures changes are applied in order
        setTimeout(() => {
          // Force a final update to any components that depend on these states
          setUploading(false)
        }, 20)
      }, 30)

      // Force any lingering beforeunload listeners to be removed
      const cleanupFunction = () => { return undefined } // Explicit return to avoid empty function lint error
      window.onbeforeunload = null // Also clear the onbeforeunload property
      window.addEventListener('beforeunload', cleanupFunction)
      window.removeEventListener('beforeunload', cleanupFunction)
    }
  }

  const handleUploadFiles = async () => {
    if (files.length === 0) return

    // Count valid files (not oversized and not already flagged with errors)
    const validFiles = files.filter((file, index) =>
      file.size <= MAX_FILE_SIZE &&
      (!fileStatuses[index] || fileStatuses[index].status !== 'error')
    )

    if (validFiles.length === 0) {
      // All files are invalid - show a message but don't start upload
      if (oversizedFiles.length > 0) {
        // We already show the oversized files warning, so no need for additional message
        return
      }
      return
    }

    setUploading(true)
    setIsClosingBlocked(true)
    setShowSuccessMessage(false)

    // Check for duplicate filenames first
    const fileNames = validFiles.map(file => file.name)
    const duplicates = fileNames.filter((name, index) => fileNames.indexOf(name) !== index)

    if (duplicates.length > 0) {
      alert(`Doppelte Dateinamen erkannt: ${duplicates.join(', ')}. Bitte stellen Sie sicher, dass alle Dateinamen eindeutig sind.`)
      setUploading(false)
      setIsClosingBlocked(false)
      return
    }

    try {
      let targetParentId = parentId

      // If this is a folder upload, create a folder first
      if (isFolderUpload && folderName) {
        console.log(`Creating new folder: ${folderName}`)
        const folderId = await createNewFolder(folderName)

        if (folderId) {
          console.log(`Created folder with ID: ${folderId}`)
          targetParentId = folderId
          setNewFolderId(folderId)
        } else {
          console.error('Failed to create folder')
          alert(`Fehler beim Erstellen des Ordners "${folderName}". Die Dateien werden im aktuellen Ordner hochgeladen.`)
        }
      }

      // Reset references
      uploadQueueRef.current = Array.from({ length: files.length }, (_, i) => i)
        .filter(i => files[i].size <= MAX_FILE_SIZE &&
                    (!fileStatuses[i] || fileStatuses[i].status !== 'error'))
      activeUploadsRef.current = 0
      uploadTasksRef.current = {}

      // Start the upload process, using the new parent ID if a folder was created
      processUploadQueue(targetParentId)
    } catch (error) {
      console.error('Batch upload error:', error)
      setUploading(false)
      setIsClosingBlocked(false)
    }
  }

  const handleClose = () => {
    // Only allow closing if not currently uploading
    if (!isClosingBlocked) {
      setBatchUploadDialogOpen(false)

      // Check if any files were uploaded successfully before resetting
      const anyFilesUploaded = uploadStats.completed > 0

      // Reset states
      setFiles([])
      setFileStatuses({})
      setExistingFiles([])
      setOverallProgress(0)
      setUploadStats({ total: 0, completed: 0, errors: 0, skipped: 0 })
      setShowSuccessMessage(false)

      // Reset both input fields
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }

      if (folderInputRef.current) {
        folderInputRef.current.value = ''
      }

      // Refresh the folder tree if any files were uploaded
      if (anyFilesUploaded) {
        refreshFolderTree()
      }

      // Ensure uploading is set to false
      setUploading(false)

      // Force any lingering beforeunload listeners to be removed
      const cleanupFunction = () => { return undefined } // Explicit return to avoid empty function lint error
      window.onbeforeunload = null // Also clear the onbeforeunload property
      window.addEventListener('beforeunload', cleanupFunction)
      window.removeEventListener('beforeunload', cleanupFunction)
    }
  }

  const getStatusIcon = (status) => {
    switch (status) {
      case 'completed':
        return <CheckCircleIcon sx={{ color: getStatusColor('completed') }} />
      case 'error':
        return <ErrorIcon sx={{ color: getStatusColor('error') }} />
      case 'exists':
        return <WarningIcon sx={{ color: getStatusColor('exists') }} />
      case 'uploading':
        return <CircularProgress size={20} sx={{ color: getStatusColor('uploading') }} />
      case 'retrying':
        return <RefreshIcon sx={{ color: getStatusColor('uploading') }} />
      default:
        return null
    }
  }

  const getStatusText = (file, index) => {
    const status = fileStatuses[index]?.status

    if (!status) return 'Warten'

    switch (status) {
      case 'uploading':
        return `Upload läuft (${Math.round(fileStatuses[index]?.progress || 0)}%)${
          fileStatuses[index]?.retryCount > 0 ? ` - Versuch ${fileStatuses[index].retryCount + 1}/${MAX_RETRIES + 1}` : ''
        }`
      case 'retrying':
        return `Erneuter Versuch wird gestartet... (${fileStatuses[index]?.retryCount + 1}/${MAX_RETRIES + 1})`
      case 'completed':
        return 'Abgeschlossen'
      case 'error':
        return `Fehler: ${fileStatuses[index]?.error || 'Unbekannter Fehler'}`
      case 'exists':
        return 'Datei existiert bereits in diesem Ordner'
      default:
        return status
    }
  }

  const allCompleted = files.length > 0 &&
    Object.keys(fileStatuses).length === files.length &&
    Object.values(fileStatuses).every(status =>
      status.status === 'completed' || status.status === 'error' || status.status === 'exists'
    )

  const isProcessComplete = allCompleted ||
    (files.length > 0 && uploadStats.completed + uploadStats.errors + uploadStats.skipped === files.length)

  // Add an effect to ensure isClosingBlocked is properly reset when process completes
  useEffect(() => {
    if (isProcessComplete && isClosingBlocked) {
      setIsClosingBlocked(false)

      // Force a re-render after completion is detected
      setTimeout(() => {
        // This empty setState forces a re-render
        setUploading(false)
      }, 50)
    }
  }, [isProcessComplete, isClosingBlocked])

  const LargeFileSetStats = () => {
    if (files.length < 20) return null // Only show for larger file sets

    const pendingCount = files.length -
      (uploadStats.completed + uploadStats.errors + uploadStats.skipped) -
      Object.values(fileStatuses).filter(s => s?.status === 'uploading' || s?.status === 'retrying').length

    const processingCount = Object.values(fileStatuses).filter(
      s => s?.status === 'uploading' || s?.status === 'retrying'
    ).length

    return (
      <Box sx={{ mt: 2, mb: 1, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
        <Typography variant="subtitle2" gutterBottom>
          Upload-Status für {files.length} Dateien:
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', mb: 1 }}>
          <Typography variant="body2">
            <strong>Warten:</strong> {pendingCount}
          </Typography>
          <Typography variant="body2">
            <strong>In Bearbeitung:</strong> {processingCount}
          </Typography>
          <Typography variant="body2">
            <strong>Abgeschlossen:</strong> {uploadStats.completed}
          </Typography>
          <Typography variant="body2">
            <strong>Fehler:</strong> {uploadStats.errors}
          </Typography>
          <Typography variant="body2">
            <strong>Übersprungen:</strong> {uploadStats.skipped}
          </Typography>
        </Box>
        {uploading && (
          <Typography variant="caption" sx={{ display: 'block', mt: 1, color: 'text.secondary' }}>
            Die Dateien werden in Batches von {CONCURRENT_UPLOAD_LIMIT} gleichzeitig hochgeladen, um die Netzwerkressourcen zu schonen.
          </Typography>
        )}
      </Box>
    )
  }

  // Add a helper function to handle close button clicks
  const handleCloseButtonClick = () => {
    if (isClosingBlocked && uploading) {
      // Show warning if uploads are running
      setShowRefreshWarning(true)
    } else {
      handleClose()
    }
  }

  const BatchUploadDialog = (
    <>
      <Dialog
        open={batchUploadDialogOpen}
        onClose={(event, reason) => {
          // Only handle non-disruptive close events like clicking the X
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleCloseButtonClick()
          } else if (isClosingBlocked) {
            // For backdrop and escape key, show warning if upload is in progress
            setShowRefreshWarning(true)
          }
        }}
        maxWidth="md"
        fullWidth
        disableEscapeKeyDown={isClosingBlocked}
      >
        {uploading && <LinearProgress variant="determinate" value={overallProgress} className={styles.overallProgress} />}
        <DialogTitle className={styles.dialogTitle}>
          Batch Datei-Upload
          {uploading && (
            <Typography variant="subtitle2" color="textSecondary" className="subtitle">
              Bitte Dialog nicht schließen. Upload läuft...
            </Typography>
          )}
          {showSuccessMessage && !uploading && (
            <>
              <Typography variant="subtitle2" className={styles.successMessage}>
                Upload abgeschlossen!
              </Typography>
              {uploadStats.errors > 0 && (
                <Typography variant="caption" color="error" sx={{ display: 'block', mt: 1 }}>
                  {uploadStats.errors} Dateien konnten nicht hochgeladen werden.
                </Typography>
              )}
              <Typography variant="caption" sx={{ display: 'block', mt: 1, color: 'text.secondary' }}>
                Sie können weitere Dateien zum Hochladen auswählen oder das Fenster schließen.
              </Typography>
            </>
          )}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Mehrere PDF-Dateien gleichzeitig hochladen
          </Typography>

          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Option 1: Einzelne Dateien auswählen
            </Typography>
            <input
              type="file"
              onChange={handleFileChange}
              accept=".pdf"
              multiple
              disabled={uploading}
              ref={fileInputRef}
              className={styles.fileInput}
            />
          </Box>

          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Option 2: Ordner auswählen
            </Typography>
            <input
              type="file"
              onChange={handleFolderChange}
              accept=".pdf"
              multiple
              disabled={uploading}
              // Directory attributes for Chrome, Edge and other browsers
              {...{ directory: '', webkitdirectory: '', mozdirectory: '' }}
              className={styles.fileInput}
              ref={folderInputRef}
            />
            <Typography variant="caption" color="textSecondary">
              Unterstützt nur in Chrome, Edge und anderen Chromium-basierten Browsern.
            </Typography>
          </Box>

          {files.length > 0 && (
            <>
              <Box className={styles.progressContainer}>
                <Box className={styles.statusBox} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Gesamtfortschritt: {Math.round(overallProgress)}%
                  </Typography>
                  <Typography variant="body2">
                    {uploadStats.completed} von {files.length} abgeschlossen
                    {uploading ? ' (Läuft...)' : allCompleted ? ' (Abgeschlossen)' : ' (Bereit)'}
                  </Typography>
                </Box>
              </Box>

              <Divider style={{ margin: '12px 0' }} />

              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                <Typography variant="subtitle1" fontWeight="bold">
                  Dateien ({files.length})
                </Typography>

                <Box sx={{ display: 'flex', gap: 2 }} className="status-summary">
                  {uploadStats.completed > 0 && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }} className="status-badge">
                      <CheckCircleIcon fontSize="small" sx={{ color: getStatusColor('completed') }} />
                      <Typography variant="body2">{uploadStats.completed}</Typography>
                    </Box>
                  )}

                  {uploadStats.errors > 0 && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }} className="status-badge">
                      <ErrorIcon fontSize="small" sx={{ color: getStatusColor('error') }} />
                      <Typography variant="body2">{uploadStats.errors}</Typography>
                    </Box>
                  )}

                  {uploadStats.skipped > 0 && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }} className="status-badge">
                      <WarningIcon fontSize="small" sx={{ color: getStatusColor('exists') }} />
                      <Typography variant="body2">{uploadStats.skipped}</Typography>
                    </Box>
                  )}
                </Box>
              </Box>

              <Paper elevation={1} className={styles.fileStatusPaper}>
                <List dense disablePadding>
                  {files.map((file, index) => (
                    <ListItem
                      key={`${file.name}-${index}`}
                      sx={getFileListItemStyle(fileStatuses[index]?.status || 'pending')}
                      secondaryAction={
                        !uploading && fileStatuses[index]?.status !== 'completed' ? (
                          <>
                            {fileStatuses[index]?.status === 'error' && (
                              <Tooltip title="Erneut versuchen">
                                <IconButton edge="end" onClick={() => retryFile(index)} disabled={uploading}>
                                  <RefreshIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                            <Tooltip title="Entfernen">
                              <IconButton edge="end" onClick={() => removeFile(index)} disabled={uploading}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </>
                        ) : null
                      }
                    >
                      <Box className={styles.statusIconBox}>
                        {getStatusIcon(fileStatuses[index]?.status)}
                      </Box>
                      <ListItemText
                        primary={file.name}
                        secondary={getStatusText(file, index)}
                      />
                      {fileStatuses[index]?.status === 'uploading' && (
                        <Box className={styles.progressBarWrapper}>
                          <LinearProgress
                            variant="determinate"
                            value={fileStatuses[index]?.progress || 0}
                            className={styles.fileProgressBar}
                          />
                        </Box>
                      )}
                    </ListItem>
                  ))}
                </List>
              </Paper>

              {existingFiles.length > 0 && (
                <Alert
                  severity="warning"
                  className={styles.warningAlert}
                  icon={<WarningIcon sx={{ color: getStatusColor('exists') }} />}
                >
                  Die folgenden Dateien existieren bereits im Zielordner und wurden übersprungen:
                  <Box component="ul" sx={{ m: 0, pl: 2 }}>
                    {existingFiles.map((fileName, i) => (
                      <li key={i}>{fileName}</li>
                    ))}
                  </Box>
                </Alert>
              )}

              {oversizedFiles.length > 0 && (
                <Alert
                  severity="error"
                  className={styles.warningAlert}
                  icon={<ErrorIcon sx={{ color: getStatusColor('error') }} />}
                  sx={{ mt: 2 }}
                >
                  {oversizedFiles.length === 1 ? (
                    'Eine Datei ist größer als 30MB und kann nicht hochgeladen werden.'
                  ) : (
                    `${oversizedFiles.length} Dateien sind größer als 30MB und können nicht hochgeladen werden.`
                  )}
                  <Box component="ul" sx={{ m: 0, pl: 2 }}>
                    {oversizedFiles.slice(0, 5).map((file, i) => (
                      <li key={i}>{file.name} ({(file.size / (1024 * 1024)).toFixed(2)} MB)</li>
                    ))}
                    {oversizedFiles.length > 5 && (
                      <li>...und {oversizedFiles.length - 5} weitere</li>
                    )}
                  </Box>
                </Alert>
              )}

              {!uploading && isProcessComplete && uploadStats.errors > 0 && (
                <Alert
                  severity="error"
                  className={styles.warningAlert}
                  icon={<ErrorIcon sx={{ color: getStatusColor('error') }} />}
                  sx={{ mt: 2 }}
                >
                  {uploadStats.errors === 1 ? (
                    'Eine Datei konnte nicht hochgeladen werden.'
                  ) : (
                    `${uploadStats.errors} Dateien konnten nicht hochgeladen werden.`
                  )}
                  {
                    Object.entries(fileStatuses)
                      .filter(([_, status]) => status.status === 'error')
                      .length > 0 && (
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          Verwenden Sie die Schaltfläche &quot;Erneut versuchen&quot;, um fehlgeschlagene Uploads erneut zu starten.
                        </Typography>
                      )
                  }
                </Alert>
              )}

              <LargeFileSetStats />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseButtonClick}
            disabled={false} // Never disable the close button
            isCTA={!uploading && (files.length === 0 || isProcessComplete)}
            label={uploading ? 'Bitte warten...' : 'Schließen'}
          />
          <Button
            onClick={handleUploadFiles}
            disabled={files.length === 0 || uploading || isProcessComplete}
            isCTA={files.length > 0 && !uploading && !isProcessComplete}
            label={uploading ? 'Läuft...' : isProcessComplete ? 'Abgeschlossen' : 'Hochladen'}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        open={showRefreshWarning && uploading}
        onClose={() => setShowRefreshWarning(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ color: getStatusColor('error') }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <WarningIcon />
            Achtung: Upload läuft
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Es laufen noch Uploads. Wenn Sie jetzt die Seite verlassen oder den Dialog schließen, werden alle laufenden Uploads abgebrochen.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Bitte warten Sie, bis alle Uploads abgeschlossen sind, bevor Sie die Seite verlassen.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowRefreshWarning(false)}
            disabled={false}
            isCTA={true}
            label="Verstanden"
          />
        </DialogActions>
      </Dialog>
    </>
  )

  // Extract folder name from file paths
  const extractFolderNameFromFiles = (files) => {
    if (files.length === 0) return null

    // Get the first file path
    const firstFilePath = files[0].webkitRelativePath || ''

    // Extract the top-level folder name
    const folderPathParts = firstFilePath.split('/')
    if (folderPathParts.length >= 1) {
      return folderPathParts[0]
    }

    return null
  }

  // Create a folder and return its ID
  const createNewFolder = async (folderName) => {
    try {
      // Create folder
      const folder = await createFolder(userId, folderName, parentId)
      if (folder) {
        return folder.id
      }
    } catch (error) {
      console.error('Error creating folder:', error)
    }
    return null
  }

  return { handleBatchUploadClick, BatchUploadDialog }
}

export default useBatchUploadLogic
