import React, { useState } from 'react'
import {
  Popover,
  MenuItem,
  List,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Menu,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import styles from '../../../../../styles/styles'
import { useDispatch, useSelector } from 'react-redux'
import { setPDFViewerActive, setPDFViewerBlobUrl } from '../../../../../slices/collaborativeDocumentAnonymizationStateSlice'
import useCreateFolderLogic from './Logics/CreateFolderLogic'
import useUploadFileLogic from './Logics/UploadFileLogic'
import useBatchUploadLogic from './Logics/BatchUploadLogic'
import useBatchAnonymizationLogic from './Logics/BatchAnonymizationLogic'
import useBatchDownloadLogic from './Logics/BatchDownloadLogic'
import { downloadAndDecryptFile } from './Logics/DownloadFileLogic'
import useDeleteFileLogic from './Logics/DeleteFileLogic'
import useDeleteFolderLogic from './Logics/DeleteFolderLogic'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '../../../../../components/Button/Button'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

const StyledPopover = styled(Popover)`
  .MuiPaper-root {
    border-radius: 4px;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);
    padding: ${styles.spacing.tiny}; // Decreased padding
  }
`

function TreeItemsContextMenu ({ position, setContextMenu, id, type, refreshFolderTree, onFileDelete }) {
  const [batchDialogOpen, setBatchDialogOpen] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('')
  const [batchMenuAnchor, setBatchMenuAnchor] = useState(null)
  const userId = useSelector((state) => state.auth.userId)
  const userLevel = useSelector((state) => state.auth.userLevel)
  const blobUrl = useSelector((state) => state.selectedFileSlice[id]?.blobUrl)
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const handleContextMenuClose = () => {
    setContextMenu(null)
  }

  const handleBatchMenuOpen = (event) => {
    setBatchMenuAnchor(event.currentTarget)
  }

  const handleBatchMenuClose = () => {
    setBatchMenuAnchor(null)
  }

  const { handleCreateFolderClick, CreateFolderDialog } = useCreateFolderLogic(userId, id, refreshFolderTree, setContextMenu)
  const { handleUploadFileClick, UploadFileDialog } = useUploadFileLogic(userId, id, refreshFolderTree, setContextMenu)
  const { handleBatchUploadClick: handleAdminBatchUploadClick, BatchUploadDialog } = useBatchUploadLogic(userId, id, refreshFolderTree, setContextMenu)
  const { handleBatchAnonymizationClick: handleAdminBatchAnonymizationClick, BatchAnonymizationDialog } = useBatchAnonymizationLogic(userId, id, refreshFolderTree, setContextMenu)
  const { handleBatchDownloadClick: handleAdminBatchDownloadClick, BatchDownloadDialog } = useBatchDownloadLogic(userId, id, refreshFolderTree, setContextMenu)
  const { handleDeleteFileClick, DeleteFileDialog } = useDeleteFileLogic(userId, id, refreshFolderTree, setContextMenu, onFileDelete)
  const { handleDeleteFolderClick, DeleteFolderDialog } = useDeleteFolderLogic(userId, id, refreshFolderTree, setContextMenu)

  const handleBatchAnonymizationClick = () => {
    setBatchMenuAnchor(null)
    setContextMenu(null)

    if (userLevel === 'admin') {
      handleAdminBatchAnonymizationClick()
    } else {
      setDialogTitle('Batch Anonymisierung')
      setBatchDialogOpen(true)
    }
  }

  const handleBatchUploadClick = () => {
    setBatchMenuAnchor(null)
    setContextMenu(null)

    if (userLevel === 'admin') {
      handleAdminBatchUploadClick()
    } else {
      setDialogTitle('Batch Upload')
      setBatchDialogOpen(true)
    }
  }

  const handleBatchDownloadClick = () => {
    setBatchMenuAnchor(null)
    setContextMenu(null)

    if (userLevel === 'admin') {
      handleAdminBatchDownloadClick()
    } else {
      setDialogTitle('Batch Download')
      setBatchDialogOpen(true)
    }
  }

  const handleContactSales = () => {
    setBatchDialogOpen(false)
    navigate('/#kontakt', { state: { from: location.pathname } })
  }

  const handleDownloadFileClick = () => {
    setContextMenu(null)
    downloadAndDecryptFile(userId, id, false)
  }

  const handlePreviewFileClick = () => {
    setContextMenu(null)
    dispatch(setPDFViewerBlobUrl(blobUrl))
    dispatch(setPDFViewerActive(true))
  }

  return (
    <>
      <StyledPopover
        id="simple-menu"
        anchorReference="anchorPosition"
        anchorPosition={position}
        keepMounted
        open={Boolean(position)}
        onClose={handleContextMenuClose}
        PaperProps={{
          style: {
            borderRadius: 4,
            boxShadow: '1px 1px 5px 2px rgba(0, 0, 0, 0.2)',
            padding: styles.spacing.tiny
          }
        }}
      >
        <List style={{ textAlign: 'center' }}>
          {type === 'folder' && (
            <>
              <MenuItem onClick={handleUploadFileClick}>Datei hochladen</MenuItem>
              <MenuItem onClick={handleCreateFolderClick}>Ordner erstellen</MenuItem>
              <MenuItem onClick={handleDeleteFolderClick}>Ordner löschen</MenuItem>
              <Divider style={{ margin: '5px 0' }} />
              <MenuItem onClick={handleBatchMenuOpen}>
                <ListItemText primary="Batch Operationen" />
                <ListItemIcon style={{ minWidth: '24px' }}>
                  <ArrowRightIcon fontSize="small" />
                </ListItemIcon>
              </MenuItem>
            </>
          )}
          {type === 'file' && (
            <>
              <MenuItem onClick={handlePreviewFileClick}>Vorschau</MenuItem>
              <MenuItem onClick={handleDownloadFileClick}>Herunterladen</MenuItem>
              <MenuItem onClick={handleDeleteFileClick}>Löschen</MenuItem>
            </>
          )}
        </List>
      </StyledPopover>

      {/* Submenu for Batch Operations */}
      <Menu
        id="batch-operations-menu"
        anchorEl={batchMenuAnchor}
        keepMounted
        open={Boolean(batchMenuAnchor)}
        onClose={handleBatchMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{
          style: {
            borderRadius: 4,
            boxShadow: '1px 1px 5px 2px rgba(0, 0, 0, 0.2)',
            padding: styles.spacing.tiny
          }
        }}
      >
        <MenuItem onClick={handleBatchUploadClick}>Batch Upload</MenuItem>
        <MenuItem onClick={handleBatchAnonymizationClick}>Batch Anonymisierung</MenuItem>
        <MenuItem onClick={handleBatchDownloadClick}>Batch Download</MenuItem>
      </Menu>

      {CreateFolderDialog}
      {DeleteFileDialog}
      {UploadFileDialog}
      {DeleteFolderDialog}
      {BatchUploadDialog}
      {BatchAnonymizationDialog}
      {BatchDownloadDialog}

      <Dialog open={batchDialogOpen} onClose={() => setBatchDialogOpen(false)}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <p>Bitte kontaktieren Sie das Vertriebsteam, um diese Funktion zu aktivieren.</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setBatchDialogOpen(false)}
            label="Abbrechen"
            isCTA={false}
          />
          <Button
            onClick={handleContactSales}
            label="Kontakt aufnehmen"
            isCTA={true}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

TreeItemsContextMenu.propTypes = {
  position: PropTypes.object,
  setContextMenu: PropTypes.func,
  id: PropTypes.string,
  type: PropTypes.string,
  refreshFolderTree: PropTypes.func,
  onFileDelete: PropTypes.func.isRequired
}

export default TreeItemsContextMenu
