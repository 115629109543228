import React, { useMemo, useState } from 'react'
import { Typography, Switch, Collapse, IconButton } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import PropTypes from 'prop-types'
import _ from 'lodash'

const GroupedEntitiesList = ({ anonymizations, onToggleChange }) => {
    const [expandedGroups, setExpandedGroups] = useState({})

    const groupedEntities = useMemo(() => {
        const groupsByLabel = _.groupBy(anonymizations, 'label')

        // Sort labels to ensure Bild and Grafik appear at the end
        const sortedEntries = Object.entries(groupsByLabel).sort(([labelA], [labelB]) => {
            if (labelA === 'Bild') return 1
            if (labelB === 'Bild') return -1
            if (labelA === 'Grafik') return 1
            if (labelB === 'Grafik') return -1
            return 0
        })

        return sortedEntries.map(([label, entities]) => {
            const uniqueEntitiesByText = _.uniqBy(entities, 'text')
            const textInstanceCounts = _.countBy(entities, 'text')
            const entitiesWithCounts = uniqueEntitiesByText.map(entity => ({
                ...entity,
                instances: textInstanceCounts[entity.text]
            }))

            const totalCount = entities.length
            const activeCount = entities.filter(e => e.annonymize).length

            let status = 'none'
            if (activeCount === totalCount) status = 'all'
            else if (activeCount === 0) status = 'none'
            else status = 'partial'

            return {
                label,
                entities: entitiesWithCounts,
                allEntities: entities,
                status,
                totalCount,
                activeCount
            }
        })
    }, [anonymizations])

    const toggleGroup = (label) => {
        setExpandedGroups(prev => ({
            ...prev,
            [label]: !prev[label]
        }))
    }

    const handleGroupToggle = (label) => {
        const group = groupedEntities.find(g => g.label === label)
        if (!group) return

        const newState = group.status === 'none'

        group.entities.forEach(entity => {
            if (entity.annonymize !== newState) {
                onToggleChange(entity.text, newState)
            }
        })
    }

    const getGroupColor = (status) => {
        switch (status) {
            case 'all': return '#2F4550' // $cadet
            case 'none': return 'rgba(47, 69, 80, 0.38)' // $darkgray-transparent
            case 'partial': return '#586F7C' // lighter variant of $cadet
            default: return 'rgba(47, 69, 80, 0.38)' // $darkgray-transparent
        }
    }

    return (
        <div className="grouped-entities-list">
            {groupedEntities.map(({ label, entities, status, totalCount, activeCount }) => (
                <div key={label} className="group-container">
                    <div
                        className="group-header"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '8px',
                            backgroundColor: 'rgb(245, 245, 245)',
                            borderRadius: '4px',
                            marginBottom: '4px'
                        }}
                    >
                        <IconButton
                            size="small"
                            onClick={() => toggleGroup(label)}
                            style={{ marginRight: '8px' }}
                        >
                            {expandedGroups[label] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>

                        <div className="toggle-item">
                            <Switch
                                checked={status === 'all'}
                                onChange={() => handleGroupToggle(label)}
                                size="small"
                                indeterminate={status === 'partial'}
                            />
                        </div>

                        <Typography
                            variant="subtitle1"
                            style={{
                                marginLeft: '8px',
                                color: getGroupColor(status),
                                flex: 1,
                                fontStyle: label === 'Bild' || label === 'Grafik' ? 'italic' : 'normal'
                            }}
                        >
                            {label} ({activeCount}/{totalCount})
                        </Typography>
                    </div>

                    <Collapse in={expandedGroups[label]}>
                        <div style={{ marginLeft: '32px' }}>
                            {entities.map((item, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '4px 8px'
                                    }}
                                >
                                    <div className="toggle-item">
                                        <Switch
                                            checked={item.annonymize || false}
                                            onChange={() => onToggleChange(item.text)}
                                            size="small"
                                        />
                                    </div>
                                    <Typography variant="body2" style={{ marginLeft: '8px' }}>
                                        {item.text} ({item.instances} {item.instances === 1 ? 'Vorkommen' : 'Vorkommen'})
                                    </Typography>
                                </div>
                            ))}
                        </div>
                    </Collapse>
                </div>
            ))}
        </div>
    )
}

GroupedEntitiesList.propTypes = {
    anonymizations: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        annonymize: PropTypes.bool,
        type: PropTypes.string
    })).isRequired,
    onToggleChange: PropTypes.func.isRequired
}

export default GroupedEntitiesList
